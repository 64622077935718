import { FC } from 'react';
import classNames from 'classnames';

import { ProviderCollection } from '../../../generated/graphql';
import { ClipboardListIcon, HomeIcon } from '@heroicons/react/outline';

const AllResourcesButton: FC<{
  resourceName?: string;
  selectedProviderCollection: ProviderCollection | undefined;
  setSelectedProviderCollection: (
    value: React.SetStateAction<ProviderCollection | undefined>,
  ) => void;
  communityResourcesSelected: boolean;
  followUpMode: boolean;
}> = ({
  resourceName = 'resource',
  selectedProviderCollection,
  setSelectedProviderCollection,
  communityResourcesSelected,
  followUpMode,
}) => (
  <div
    className={classNames('w-full border-neutral-75 pr-5', {
      'mb-2 border-b border-neutral-75 py-2': followUpMode,
      'pt-2': !followUpMode,
    })}
  >
    <div
      className={classNames(
        'flex min-h-[40px] w-full cursor-pointer flex-row items-center justify-start overflow-y-auto rounded-md pr-[28px] pl-4 hover:rounded-md hover:bg-neutral-25',
        {
          'rounded-md bg-secondary-20 font-medium hover:bg-secondary-25':
            !selectedProviderCollection?.id && !communityResourcesSelected,
        },
      )}
      onClick={() => {
        setSelectedProviderCollection(undefined);
      }}
    >
      {resourceName === 'form' ? (
        <ClipboardListIcon className="ml-1 mr-2 h-4 w-4 text-secondary-125" />
      ) : (
        <HomeIcon className="ml-1 mr-2 h-4 w-4 text-secondary-125" />
      )}
      <div className="text-caption font-medium text-neutral-150">
        All {resourceName}s
      </div>
    </div>
  </div>
);

export default AllResourcesButton;
