import { ArchiveIcon } from '@heroicons/react/outline';
import { FC } from 'react';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import { QuickActionProps } from './QuickAction';
import classNames from 'classnames';

const OnboardingNotification: FC<{
  step: QuickActionProps;
}> = ({ step }) => {
  const IconComponent = step?.IconComponent;

  return (
    <div
      className="mb-2 flex w-full cursor-pointer flex-row items-center rounded-lg py-3 px-5 hover:bg-secondary-20 focus:bg-neutral-50 focus:outline-none"
      onClick={step.onClick}
    >
      <IconComponent
        className={classNames(
          'mr-4 h-8 w-8 shrink-0 grow-0',
          step.iconClassName,
        )}
      />
      <div className="mr-10 flex-grow">
        <div className="flex flex-col items-start justify-center text-caption text-neutral-150 line-clamp-2">
          <div className="font-medium">{step.title}</div>
          <div>{step.description}</div>
        </div>
      </div>
      <div className="flex flex-row">
        <Button
          size="small"
          title={step.buttonTitle ?? 'Get Started'}
          theme="secondary"
          onClick={(e) => {
            e.stopPropagation();
            step.onClick();
          }}
        />
        <IconButton
          className="ml-5"
          iconClassName="w-5 h-5 text-secondary-100"
          IconComponent={ArchiveIcon}
          aria-label="Mark notification read"
          onClick={(e) => {
            e.stopPropagation();
            step.onDismiss();
          }}
        />
      </div>
    </div>
  );
};

export default OnboardingNotification;
