import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import Config from './config';
import { MeProviderDataFragment } from '../generated/graphql';

const safeSentryCall = (callback: () => void) => {
  try {
    if (Config.REACT_APP_SENTRY_DSN) {
      callback();
    } else {
      console.warn('Sentry DSN is not set, skipping Sentry operation');
    }
  } catch (error) {
    console.error('Failed to send data to Sentry:', error);
  }
};

const initSentry = () => {
  if (Config.REACT_APP_SENTRY_DSN)
    safeSentryCall(() => {
      Sentry.init({
        debug: Config.REACT_APP_SENTRY_DEBUG,
        dsn: Config.REACT_APP_SENTRY_DSN,
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
        ],
        environment: Config.REACT_APP_ENVIRONMENT,
        release: Config.REACT_APP_VERSION,
        tracesSampleRate: parseFloat(Config.REACT_APP_SENTRY_SAMPLE_RATE),
      });
    });
};

const setUserScope = (meProvider: MeProviderDataFragment) => {
  safeSentryCall(() => {
    Sentry.setTag('providerUserId', meProvider.id);
    Sentry.setTag('providerId', meProvider.provider.id);
    Sentry.setTag('role', meProvider.role);
  });
};

const clearUserScope = () => {
  safeSentryCall(() => {
    Sentry.setTag('providerUserId', null);
    Sentry.setTag('providerId', null);
    Sentry.setTag('role', null);
  });
};

const SentryHelpers = {
  initSentry,
  setUserScope,
  clearUserScope,
  safeSentryCall,
};

export default SentryHelpers;
