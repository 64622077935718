import { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { DateTime } from 'luxon';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin, {
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import { useAuth } from '../../../../contexts/AuthContext';
import { NylasCalendarEventDataFragment } from '../../../../generated/graphql';
import { useCalendarEvents } from './hooks/useCalendarEvents';
import { CalendarHeader } from './components/CalendarHeader';
import EventDetailsPopover from './components/EventDetailsPopover';
import CreateEventModal from './components/CreateEventModal';
import EditEventModal from './components/EditEventModal';
import ConfirmDeleteModal from '../../../components/ConfirmDeleteModal';
import './CalendarV2.styles.css';
import { EventDropArg } from '@fullcalendar/core';
import { useEventMutation } from './hooks/useEventMutations';
import { useCalendars } from './hooks/useCalendars';
import { useResources } from './hooks/useResources';

import { logger } from '../../../../lib/logger';
import { useAutoRefresh } from './hooks/useAutoRefresh';
import { useCalendarViewState } from './hooks/useCalendarViewState';
import { CalendarViewType } from './utils/enums';

const Calendar = () => {
  const { authedProviderUser } = useAuth();
  const calendarRef = useRef(null);
  const isNylasConnected = authedProviderUser?.hasNylasIntegration;

  // Fetch available calendars and resources
  const { calendars, loading: isLoadingCalendars } = useCalendars();
  const { resources, loading: isLoadingResources } = useResources();

  // View state management
  const {
    viewType,
    viewMode,
    selectedCalendarIds,
    selectedResourceIds,
    handleViewTypeChange,
    handleCalendarSelect,
    handleResourceSelect,
  } = useCalendarViewState(calendarRef, calendars, resources);

  const {
    currentDate,
    setCurrentDate,
    events,
    isLoading: isLoadingEvents,
    isDeletingEvent,
    primaryCalendar,
    handleDeleteEvent,
    refetchEvents,
  } = useCalendarEvents(
    isNylasConnected,
    viewType === CalendarViewType.CALENDARS
      ? selectedCalendarIds
      : selectedResourceIds,
  );

  // Event mutation handlers
  const { handleEventDragOrResize } = useEventMutation();

  // Auto refresh
  const refreshCallback = useCallback(() => {
    void refetchEvents();
  }, [refetchEvents]);

  useAutoRefresh(refreshCallback, {
    seconds: 30,
    enabled: isNylasConnected,
  });

  // State for modals and popovers
  const [selectedEvent, setSelectedEvent] =
    useState<NylasCalendarEventDataFragment | null>(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const [isEventDetailsPopoverOpen, setIsEventDetailsPopoverOpen] =
    useState(false);
  const [isNewEventModalOpen, setIsNewEventModalOpen] = useState(false);
  const [newEventSelectInfo, setNewEventSelectInfo] = useState(null);
  const [isEditEventModalOpen, setIsEditEventModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [targetCalendarId, setTargetCalendarId] = useState<string | null>(null);

  // Update targetCalendarId when primaryCalendar changes
  useEffect(() => {
    setTargetCalendarId(primaryCalendar?.id);
  }, [primaryCalendar]);

  // Memoize plugins array
  const calendarPlugins = useMemo(
    () => [
      dayGridPlugin,
      timeGridPlugin,
      resourceTimelinePlugin,
      resourceTimeGridPlugin,
      interactionPlugin,
    ],
    [],
  );

  // Memoize views configuration
  const calendarViews = useMemo(
    () => ({
      resourceTimeGridDay: {
        type: 'resourceTimeGrid',
        duration: { days: 3 },
        buttonText: 'Day',
      },
    }),
    [],
  );

  // Memoize event handlers
  const handleEventClick = useCallback(({ event, el }) => {
    setSelectedEvent(
      event.extendedProps.originalEvent as NylasCalendarEventDataFragment,
    );
    setPopoverAnchorEl(el);
    setIsEventDetailsPopoverOpen(true);
  }, []);

  const handleDeleteClick = useCallback(
    (event: NylasCalendarEventDataFragment) => {
      setSelectedEvent(event);
      setIsConfirmDeleteModalOpen(true);
    },
    [],
  );

  const handleModifyClick = useCallback(
    (event: NylasCalendarEventDataFragment) => {
      setSelectedEvent(event);
      setIsEditEventModalOpen(true);
      setIsEventDetailsPopoverOpen(false);
      setPopoverAnchorEl(null);
    },
    [],
  );

  const handleEventDrop = useCallback(
    (info: EventDropArg) => {
      const calendarId =
        info.event.extendedProps.calendarId || primaryCalendar?.id;
      logger.debug('🎯 Event Drop Started:', {
        eventId: info.event.id,
        start: info.event.start,
        end: info.event.end,
      });

      handleEventDragOrResize({
        info,
        calendarId,
        onSuccess: refetchEvents,
        onError: info.revert,
      });
      return true;
    },
    [primaryCalendar, handleEventDragOrResize, refetchEvents],
  );

  const handleEventResize = useCallback(
    (info: EventResizeDoneArg) => {
      const calendarId =
        info.event.extendedProps.calendarId || primaryCalendar?.id;
      logger.debug('📏 Event Resize Started:', {
        eventId: info.event.id,
        start: info.event.start,
        end: info.event.end,
      });

      handleEventDragOrResize({
        info,
        calendarId,
        onSuccess: refetchEvents,
        onError: info.revert,
      });
      return true;
    },
    [primaryCalendar, handleEventDragOrResize, refetchEvents],
  );

  const filteredResources = useMemo(() => {
    return viewType === CalendarViewType.RESOURCES
      ? resources?.filter((resource) =>
          selectedResourceIds.includes(resource.id),
        )
      : undefined;
  }, [viewType, resources, selectedResourceIds]);

  return (
    <div
      className="flex h-full flex-col"
      onMouseDown={() => {
        if (isEventDetailsPopoverOpen) {
          setIsEventDetailsPopoverOpen(false);
          setPopoverAnchorEl(null);
        }
      }}
      onTouchStart={() => {
        if (isEventDetailsPopoverOpen) {
          setIsEventDetailsPopoverOpen(false);
          setPopoverAnchorEl(null);
        }
      }}
    >
      <CalendarHeader
        viewType={viewType}
        onViewTypeChange={handleViewTypeChange}
        calendars={calendars}
        resources={resources}
        selectedCalendarIds={selectedCalendarIds}
        selectedResourceIds={selectedResourceIds}
        isLoading={isLoadingEvents}
        isLoadingCalendars={isLoadingCalendars}
        isLoadingResources={isLoadingResources}
        onTodayClick={() => {
          calendarRef.current?.getApi().today();
          setCurrentDate(DateTime.now());
        }}
        onPrevClick={() => {
          calendarRef.current?.getApi().prev();
          setCurrentDate(currentDate.minus({ weeks: 1 }));
        }}
        onNextClick={() => {
          calendarRef.current?.getApi().next();
          setCurrentDate(currentDate.plus({ weeks: 1 }));
        }}
        onCalendarSelect={handleCalendarSelect}
        onResourceSelect={handleResourceSelect}
      />

      <div className="flex-1 overflow-auto">
        <FullCalendar
          ref={calendarRef}
          initialView={viewMode}
          plugins={calendarPlugins}
          views={calendarViews}
          /* Room Resources */
          datesAboveResources={true}
          resourceLabelContent={(args) => (
            <span className="text-small-caption font-medium text-neutral-125">
              {args.resource.extendedProps.name}
            </span>
          )}
          resourceLabelClassNames="text-small-caption font-medium text-neutral-125 truncate"
          events={events}
          resources={filteredResources}
          schedulerLicenseKey="0642643305-fcs-1732645980" // TODO: Move this to config
          headerToolbar={false}
          nowIndicator={true}
          allDaySlot={true}
          handleWindowResize={true}
          slotMinTime="00:00:00"
          slotMaxTime="24:00:00"
          slotDuration="00:30:00"
          snapDuration="00:15:00"
          selectable={true}
          select={(selectInfo) => {
            logger.debug('🎯 Event Select:', {
              selectInfo,
            });
            setNewEventSelectInfo(selectInfo);
            setIsNewEventModalOpen(true);
          }}
          selectMirror={true}
          unselectAuto={true}
          eventClick={handleEventClick}
          height="100%"
          editable={true}
          eventResizableFromStart={true}
          eventDurationEditable={true}
          eventDrop={handleEventDrop}
          eventResize={handleEventResize}
          dayHeaderContent={(args) => (
            <span className="text-small-caption font-medium text-neutral-125">
              {args.text}
            </span>
          )}
          eventContent={(eventInfo) => (
            <div className="cursor-pointer p-0.5 transition-all hover:bg-opacity-90">
              <div className="truncate text-small-caption font-medium">
                {eventInfo.event.title}
              </div>
              <div className="text-small-caption opacity-80">
                {eventInfo.timeText}
              </div>
            </div>
          )}
          slotLabelContent={(args) => (
            <span className="text-small-caption font-medium text-neutral-125">
              {args.text}
            </span>
          )}
          allDayContent={() => <></>}
        />
      </div>

      <EventDetailsPopover
        event={selectedEvent}
        referenceElement={popoverAnchorEl}
        isOpen={isEventDetailsPopoverOpen}
        onClose={() => {
          setIsEventDetailsPopoverOpen(false);
          setSelectedEvent(null);
          setPopoverAnchorEl(null);
        }}
        onDeleteEventClick={handleDeleteClick}
        onModifyEventClick={handleModifyClick}
      />

      {isNewEventModalOpen && (
        <CreateEventModal
          key={newEventSelectInfo?.start?.getTime()}
          isOpen={true}
          onClose={() => {
            setIsNewEventModalOpen(false);
            setNewEventSelectInfo(null);
          }}
          selectInfo={newEventSelectInfo}
          calendarId={targetCalendarId}
          onEventCreated={() => {
            logger.debug('✅ onEventCreated');
            refetchEvents();
          }}
        />
      )}

      {isEditEventModalOpen && (
        <EditEventModal
          isOpen={true}
          calendarId={
            newEventSelectInfo?.event?.extendedProps?.originalEvent
              ?.calendarId || primaryCalendar?.id
          }
          onClose={() => {
            setIsEditEventModalOpen(false);
            setSelectedEvent(null);
          }}
          event={selectedEvent}
          onEventUpdated={() => {
            logger.debug('✅ onEventUpdated');
            refetchEvents();
          }}
        />
      )}

      {isConfirmDeleteModalOpen && (
        <ConfirmDeleteModal
          isOpen={isConfirmDeleteModalOpen}
          setClosed={() => setIsConfirmDeleteModalOpen(false)}
          performDelete={async () => {
            if (selectedEvent) {
              const success = await handleDeleteEvent(selectedEvent);
              if (success) {
                setIsConfirmDeleteModalOpen(false);
                setIsEventDetailsPopoverOpen(false);
                setSelectedEvent(null);
                setPopoverAnchorEl(null);
              }
            }
          }}
          title="Delete event?"
          fetching={isDeletingEvent}
        >
          Changes will be reflected in your external calendar and any
          participants will be notified.
        </ConfirmDeleteModal>
      )}
    </div>
  );
};

export default Calendar;
