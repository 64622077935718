import { FC, useState } from 'react';
import { useProviderUserMembersV2Query } from '../../../../generated/graphql';
import { Transition } from '@headlessui/react';
import { defaultTransitionProps } from '../../../lib/animation';
import { PlusSmIcon } from '@heroicons/react/outline';
import Button from '../../../components/Button';
import AddTeamMembersModal from './Modals/AddTeamMembersModal';
import TeamMembersTable from './TeamMembersTable';
import ToastAlert from '../../../components/ToastAlert';
import toast from 'react-hot-toast';
import { useAuth } from '../../../../contexts/AuthContext';
import { AnalyticsPage } from '../../../../lib/analytics';
import { useGlobalModalsContext } from '../../../../contexts/GlobalModalsContext';

const TeamSection: FC = () => {
  const { showUpgradeModal } = useGlobalModalsContext();
  const { authedProviderUser, refreshAuthedProviderUser } = useAuth();

  const [isAddTeamMembersModalOpen, setIsAddTeamMembersModalOpen] =
    useState(false);

  const {
    data: teamMembersData,
    loading: isLoadingTeamMembers,
    error: teamMembersError,
    refetch: refetchTeamMembers,
  } = useProviderUserMembersV2Query({
    onError(error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong getting your team members. Please try refreshing."
          level="error"
        />
      ));
    },
  });

  const teamMemberProviderUsers = teamMembersData?.providerUserMembers ?? [];

  const staffSeats = authedProviderUser?.provider?.staffSeats;
  const usedStaffSeats = teamMembersData?.providerUserMembers.filter(
    (providerUser) => !providerUser.deactivatedAt,
  ).length;

  const seatLimitReached = usedStaffSeats >= staffSeats;

  return (
    <>
      <Transition
        show={!isLoadingTeamMembers && !teamMembersError}
        {...defaultTransitionProps}
      >
        <div className="h-full">
          <div className="my-6 flex justify-end">
            <div className="flex items-center gap-x-6">
              {usedStaffSeats && staffSeats && (
                <div className="flex items-center gap-x-2">
                  <div className="text-small-caption text-neutral-110">
                    Available team seats
                  </div>
                  <div className="rounded-full bg-neutral-25 px-2 py-1 text-caption font-medium text-neutral-125">
                    {usedStaffSeats} / {staffSeats}
                  </div>
                </div>
              )}
              <Button
                title="Add team members"
                IconComponent={PlusSmIcon}
                size="small"
                iconPosition="right"
                theme="primary"
                onClick={() => {
                  if (seatLimitReached) {
                    showUpgradeModal(AnalyticsPage.SettingsTeam);
                    return;
                  }
                  setIsAddTeamMembersModalOpen(true);
                }}
              />
            </div>
          </div>

          <TeamMembersTable
            providerUserMembers={teamMemberProviderUsers}
            refetchTeamMembers={refetchTeamMembers}
            refreshAuthedProviderUser={refreshAuthedProviderUser}
            seatLimitReached={seatLimitReached}
            onSeatLimitReached={() => {
              showUpgradeModal(AnalyticsPage.SettingsTeam);
            }}
          />
        </div>
      </Transition>
      <AddTeamMembersModal
        isOpen={isAddTeamMembersModalOpen}
        existingTeamMembers={teamMemberProviderUsers}
        setClosed={() => setIsAddTeamMembersModalOpen(false)}
        refetchTeamMembers={refetchTeamMembers}
      />
    </>
  );
};

export default TeamSection;
