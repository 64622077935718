import { FC } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  ClientSession,
  ClientSessionFormType,
  useSubmitClientSessionFormMutation,
} from '../../../generated/graphql';
import Button from '../../components/Button';
import InputGroup from '../../components/InputGroup';
import TextAreaGroup from '../../components/TextAreaGroup';
import Modal from '../../components/Modal';
import { customToast } from '../../components/ToastAlert/customToast';
import { PlusSmIcon, TrashIcon } from '@heroicons/react/outline';
import RadioOption from '../../components/RadioOption';
import { logger } from '../../../lib/logger';
import SelectMenu from '../../components/SelectMenu';
import _ from 'lodash';

interface ViewClientSessionModalProps {
  clientSession: ClientSession;
  onClose: () => void;
  onSuccess: () => void;
}

interface ClientSessionFormData {
  date: string;
  startTime: string;
  endTime: string;
  isDosing: boolean;
  wasDenied: boolean;
  denyReason?: string;
  denyReasonDetails?: string;
  dosing: {
    administeredAt: string;
    substances: {
      name: string;
      amount: string;
      unit: string;
    }[];
  }[];
  postSessionReactions: boolean;
  postSessionReactionsDetails?: string;
  adverseBehavioralReactions: string;
  adverseMedicalReactions: string;
  otherNotes?: string;
}

const UNIT_OPTIONS = ['mg', 'g', 'ml', 'µg'];

const ViewClientSessionModal: FC<ViewClientSessionModalProps> = ({
  clientSession,
  onClose,
  onSuccess,
}) => {
  const [submitForm, { loading: isSubmitting }] =
    useSubmitClientSessionFormMutation();

  const {
    control,
    register,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<ClientSessionFormData>({
    defaultValues: {
      date: new Date(clientSession.startTime).toISOString().split('T')[0],
      startTime: new Date(clientSession.startTime).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }),
      endTime: new Date(clientSession.endTime).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }),
      isDosing: false,
      wasDenied: false,
      otherNotes: '',
      dosing: [
        {
          administeredAt: '',
          substances: [
            {
              name: '',
              amount: '',
              unit: 'mg',
            },
          ],
        },
      ],
      postSessionReactions: false,
      adverseBehavioralReactions: 'no',
      adverseMedicalReactions: 'no',
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dosing',
  });

  const isDosing = watch('isDosing');
  const wasDenied = watch('wasDenied');

  const removeEmptyValues = (data: any) => {
    return _.omitBy(data, (value) => {
      if (_.isObject(value)) {
        return _.isEmpty(removeEmptyValues(value));
      }
      return value === '';
    });
  };

  const onSubmit = async (formData: ClientSessionFormData) => {
    // Log client-side validation errors
    if (Object.keys(errors).length > 0) {
      logger.debug('🖥️ client-side validation errors:', errors);
    }

    try {
      logger.debug('🎯 formData', formData);

      // Format data and remove empty values.
      // Empty values are validated by the backend even if they are not required.
      const formattedData = removeEmptyValues({
        ...formData,
        dosing: formData.isDosing && !formData.wasDenied ? formData.dosing : [],
        denyReason: formData.wasDenied ? formData.denyReason : undefined,
        denyReasonDetails:
          formData.denyReason === 'other'
            ? formData.denyReasonDetails
            : undefined,
      });

      logger.debug('🎯 formattedData', formattedData);

      const response = await submitForm({
        variables: {
          input: {
            clientSessionId: clientSession.id,
            formType: ClientSessionFormType.Sb303V1,
            data: formattedData,
          },
        },
      });

      if (response.data?.submitClientSessionForm.success) {
        customToast.success('Session form submitted successfully');
        onSuccess();
        onClose();
      } else {
        // Handle validation errors from the backend
        const errors = response.data?.submitClientSessionForm.errors;
        if (errors?.length) {
          logger.debug('🔨 server-side validation errors:', errors);
          // Map backend errors to form fields if needed
          errors.forEach((error) => {
            if (error.field && error.message) {
              setError(error.field as keyof ClientSessionFormData, {
                type: 'server',
                message: error.message,
              });
            }
          });
          customToast.error('Please correct the errors in the form');
        }
      }
    } catch (err) {
      console.error('Error submitting form:', err);
      customToast.error('Failed to submit session form');
    }
  };

  return (
    <Modal name="ViewSession" isOpen={true} setClosed={onClose} width="medium">
      <form onSubmit={handleSubmit(onSubmit)} className="p-6">
        <div className="space-y-8">
          <div className="mb-6 text-center">
            <h2 className="font-serif text-subtitle text-green-150">
              Validate your session with {clientSession.patient.name}?
            </h2>
            <p className="text-caption text-neutral-125">
              Provide details for dosing or regular sessions to ensure
              regulatory compliance.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <InputGroup
              label="Date"
              useNaturalLettering
              type="date"
              {...register('date', { required: 'Date is required' })}
              errorMessage={errors.date?.message}
            />
            <InputGroup
              label="Start Time"
              useNaturalLettering
              type="time"
              {...register('startTime', {
                required: 'Start time is required',
                pattern: {
                  value: /^([01]\d|2[0-3]):([0-5]\d)$/,
                  message: 'Please enter a valid time in HH:MM format',
                },
              })}
              errorMessage={errors.startTime?.message}
            />
            <InputGroup
              label="End Time"
              useNaturalLettering
              type="time"
              {...register('endTime', {
                required: 'End time is required',
                pattern: {
                  value: /^([01]\d|2[0-3]):([0-5]\d)$/,
                  message: 'Please enter a valid time in HH:MM format',
                },
              })}
              errorMessage={errors.endTime?.message}
            />
          </div>

          <hr className="border-t border-neutral-100" />
          <Controller
            name="isDosing"
            control={control}
            render={({ field }) => (
              <div className="flex items-center gap-4">
                <span className="mt-2 min-w-[240px] font-medium">
                  Is this a dosing event?
                </span>
                <div className="flex items-center gap-4">
                  <RadioOption
                    id={`isDosing-yes`}
                    value="true"
                    checked={isDosing === true}
                    onChange={() => {
                      field.onChange(true);
                      if (fields.length === 0) {
                        append({
                          administeredAt: '',
                          substances: [
                            {
                              name: '',
                              amount: '',
                              unit: 'mg',
                            },
                          ],
                        });
                      }
                    }}
                    labelContent="Yes"
                  />
                  <RadioOption
                    id={`isDosing-no`}
                    value="false"
                    checked={isDosing === false}
                    onChange={() => {
                      field.onChange(false);
                      remove();
                    }}
                    labelContent="No"
                  />
                </div>
              </div>
            )}
          />

          {isDosing && (
            <>
              <hr className="border-t border-neutral-100" />

              <Controller
                name="wasDenied"
                control={control}
                render={({ field }) => (
                  <div className="flex items-center gap-4">
                    <span className="mt-2 min-w-[240px] font-medium">
                      Were services denied?
                    </span>
                    <div className="flex items-center gap-4">
                      <RadioOption
                        id="session-denied-yes"
                        value="true"
                        checked={field.value === true}
                        onChange={() => field.onChange(true)}
                        labelContent="Yes"
                      />
                      <RadioOption
                        id="session-denied-no"
                        value="false"
                        checked={field.value === false}
                        onChange={() => field.onChange(false)}
                        labelContent="No"
                      />
                    </div>
                  </div>
                )}
              />
            </>
          )}

          {isDosing && wasDenied && (
            <div className="mt-4 flex flex-col gap-2">
              {/* <div className="text-caption text-secondary-125">
                Please select a reason for denial:
              </div> */}
              <div className="flex flex-col gap-2">
                <Controller
                  name="denyReason"
                  control={control}
                  rules={{ required: 'Please select a reason for denial' }}
                  render={({ field }) => (
                    <>
                      <RadioOption
                        id="denyReason-inconsistentRequest"
                        value="inconsistentRequest"
                        checked={field.value === 'inconsistentRequest'}
                        onChange={() => field.onChange('inconsistentRequest')}
                        labelContent="Client requested products or services that are inconsistent with the service center business model."
                      />
                      <RadioOption
                        id="denyReason-ineligible"
                        value="ineligible"
                        checked={field.value === 'ineligible'}
                        onChange={() => field.onChange('ineligible')}
                        labelContent="Client was ineligible for services."
                      />
                      <RadioOption
                        id="denyReason-intoxicated"
                        value="intoxicated"
                        checked={field.value === 'intoxicated'}
                        onChange={() => field.onChange('intoxicated')}
                        labelContent="Client arrived at the service center intoxicated."
                      />
                      <RadioOption
                        id="denyReason-concerningBehaviors"
                        value="concerningBehaviors"
                        checked={field.value === 'concerningBehaviors'}
                        onChange={() => field.onChange('concerningBehaviors')}
                        labelContent="Client exhibited concerning behaviors after arriving at the service center."
                      />
                      <RadioOption
                        id="denyReason-other"
                        value="other"
                        checked={field.value === 'other'}
                        onChange={() => field.onChange('other')}
                        labelContent="Client was denied services for other reasons."
                      />
                      {field.value === 'other' && (
                        <div className="mt-2">
                          <TextAreaGroup
                            label="Please specify the reason for denial"
                            {...register('denyReasonDetails', {
                              required: 'Details is required',
                            })}
                            errorMessage={errors.denyReasonDetails?.message}
                          />
                        </div>
                      )}
                    </>
                  )}
                />
                {errors.denyReason && (
                  <span className="mt-1 text-small-caption text-red-100">
                    {errors.denyReason.message}
                  </span>
                )}
              </div>
            </div>
          )}

          {isDosing && !wasDenied && (
            <div className="space-y-6">
              {fields.map((field, index) => (
                <div key={field.id}>
                  <div className="mb-2 flex items-center justify-between">
                    <div className="small-caption text-xs font-semibold text-neutral-150">
                      Dose {index + 1}
                    </div>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className="text-red-125 hover:text-red-150"
                      >
                        <TrashIcon
                          className={
                            'h-7 w-7 cursor-pointer rounded-full p-1 text-neutral-125 hover:bg-neutral-125 hover:text-white'
                          }
                        />
                      </button>
                    )}
                  </div>
                  <div className="space-y-4">
                    <div className="w-1/2">
                      <InputGroup
                        label="Administered at"
                        useNaturalLettering
                        type="time"
                        {...register(`dosing.${index}.administeredAt`, {
                          required: 'Administration time is required',
                        })}
                        errorMessage={
                          errors.dosing?.[index]?.administeredAt?.message
                        }
                      />
                    </div>
                    <Controller
                      name={`dosing.${index}.substances`}
                      control={control}
                      render={({ field: substancesField }) => (
                        <div className="space-y-4">
                          {substancesField.value.map((_, substanceIndex) => (
                            <div
                              key={substanceIndex}
                              className="grid grid-cols-1 gap-4 md:grid-cols-4"
                            >
                              <div className="space-y-2">
                                <InputGroup
                                  label="Substance"
                                  useNaturalLettering
                                  placeholder="Name"
                                  {...register(
                                    `dosing.${index}.substances.${substanceIndex}.name`,
                                    { required: 'Name is required' },
                                  )}
                                  errorMessage={
                                    errors.dosing?.[index]?.substances?.[
                                      substanceIndex
                                    ]?.name?.message
                                  }
                                />
                              </div>
                              <div className="space-y-2">
                                <InputGroup
                                  label="Amount"
                                  useNaturalLettering
                                  type="number"
                                  step="0.01"
                                  placeholder="0.0"
                                  {...register(
                                    `dosing.${index}.substances.${substanceIndex}.amount`,
                                    { required: 'Amount is required' },
                                  )}
                                  errorMessage={
                                    errors.dosing?.[index]?.substances?.[
                                      substanceIndex
                                    ]?.amount?.message
                                  }
                                />
                              </div>
                              <div className="space-y-2">
                                <SelectMenu
                                  label="Unit"
                                  fieldValue={watch(
                                    `dosing.${index}.substances.${substanceIndex}.unit`,
                                  )}
                                  onChange={(value) =>
                                    setValue(
                                      `dosing.${index}.substances.${substanceIndex}.unit`,
                                      value as string,
                                    )
                                  }
                                  fieldOptions={UNIT_OPTIONS}
                                  placeholder="Select unit"
                                />
                                {errors.dosing?.[index]?.substances?.[
                                  substanceIndex
                                ]?.unit && (
                                  <span className="text-red-500">
                                    {
                                      errors.dosing[index].substances[
                                        substanceIndex
                                      ].unit.message
                                    }
                                  </span>
                                )}
                              </div>
                              <div className="flex items-center pt-6">
                                <button
                                  type="button"
                                  onClick={() => {
                                    const newSubstances = [
                                      ...substancesField.value,
                                    ];
                                    newSubstances.splice(substanceIndex, 1);
                                    substancesField.onChange(newSubstances);
                                  }}
                                  className={`text-red-125 hover:text-red-150 ${
                                    substanceIndex === 0 ? 'hidden' : ''
                                  }`}
                                >
                                  <TrashIcon
                                    className={
                                      'h-7 w-7 cursor-pointer rounded-full p-1 text-neutral-125 hover:bg-neutral-125 hover:text-white'
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          ))}

                          <button
                            type="button"
                            onClick={() => {
                              substancesField.onChange([
                                ...substancesField.value,
                                { name: '', amount: '', unit: 'mg' },
                              ]);
                            }}
                            className="hover:text-green-175 flex items-center gap-1 text-caption text-green-150"
                          >
                            <span>Add substance</span>
                            <PlusSmIcon className="h-4 w-4" />
                          </button>
                        </div>
                      )}
                    />
                  </div>
                </div>
              ))}

              <Button
                title="Add dosing event"
                size="small"
                onClick={() =>
                  append({
                    administeredAt: '',
                    substances: [
                      {
                        name: '',
                        amount: '',
                        unit: 'mg',
                      },
                    ],
                  })
                }
                theme="secondary"
                IconComponent={PlusSmIcon}
              />

              <hr className="border-t border-neutral-100" />

              <div className="grid grid-cols-2 items-center gap-4">
                <span className="col-span-1 font-medium">
                  Any adverse behavioral reactions?
                </span>
                <div className="col-span-1">
                  <SelectMenu
                    label="Reaction"
                    hideLabel={true}
                    fieldValue={watch('adverseBehavioralReactions')}
                    onChange={(value) => {
                      setValue('adverseBehavioralReactions', value as string);
                    }}
                    fieldOptions={[
                      { label: 'No adverse behavioral effects', value: 'no' },
                      { label: 'Adverse behavioral effects', value: 'yes' },
                      {
                        label: 'Severe adverse behavioral effects',
                        value: 'severe',
                      },
                    ]}
                    placeholder="Select reaction"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 items-center gap-4">
                <span className="col-span-1 font-medium">
                  Any adverse medical reactions?
                </span>
                <div className="col-span-1">
                  <SelectMenu
                    label="Reaction"
                    hideLabel={true}
                    fieldValue={watch('adverseMedicalReactions')}
                    onChange={(value) => {
                      setValue('adverseMedicalReactions', value as string);
                    }}
                    fieldOptions={[
                      { label: 'No adverse medical effects', value: 'no' },
                      { label: 'Adverse medical effects', value: 'yes' },
                      {
                        label: 'Severe adverse medical effects',
                        value: 'severe',
                      },
                    ]}
                    placeholder="Select reaction"
                  />
                </div>
              </div>

              <hr className="border-t border-neutral-100" />

              <Controller
                name="postSessionReactions"
                control={control}
                render={({ field }) => (
                  <>
                    <div className="flex items-center gap-4">
                      <span className="mt-2 min-w-[340px] font-medium">
                        Any post-session reactions?
                      </span>
                      <div className="flex items-center gap-4">
                        <RadioOption
                          id="post-session-reactions-yes"
                          value="true"
                          checked={field.value === true}
                          onChange={() => field.onChange(true)}
                          labelContent="Yes"
                        />
                        <RadioOption
                          id="post-session-reactions-no"
                          value="false"
                          checked={field.value === false}
                          onChange={() => field.onChange(false)}
                          labelContent="No"
                        />
                      </div>
                    </div>

                    {field.value && (
                      <div className="mt-4 flex flex-col gap-2">
                        <TextAreaGroup
                          label="Please provide details"
                          {...register('postSessionReactionsDetails', {
                            required: 'Details is required',
                          })}
                          errorMessage={
                            errors.postSessionReactionsDetails?.message
                          }
                        />
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          )}

          <hr className="border-t border-neutral-100" />

          <TextAreaGroup label="Other notes" {...register('otherNotes')} />

          <div className="flex justify-center">
            <Button
              title={isSubmitting ? 'Submitting...' : 'Confirm session'}
              disabled={isSubmitting}
              onClick={() => {
                // We're clearing form errors as JSON Schema conditionals
                // is not returning the error with a path, and prevents re-submission.
                clearErrors();
                handleSubmit(onSubmit)();
              }}
              type="submit"
              theme="primary"
              size="medium"
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ViewClientSessionModal;
