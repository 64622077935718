import { FC, useState } from 'react';
import {
  CuratedProviderCollection,
  useCuratedProviderCollectionsQuery,
} from '../../generated/graphql';
import BookOpen from '../svgs/BookOpen';
import Button from './Button';
import CopyToLibraryButton from './CopyToLibraryButton';
import CuratedProviderCollectionInfoModal from './Modals/CuratedProviderCollectionInfoModal';
import classNames from 'classnames';
import { useHomecomingLoader } from '../hooks/useHomecomingLoader';

const CuratedProviderCollectionTile: FC<{
  collection: CuratedProviderCollection;
  setSelectedCollection: (collection: CuratedProviderCollection) => void;
  onCollectionAdded?: () => void;
}> = ({ collection, setSelectedCollection, onCollectionAdded }) => {
  return (
    <div
      className="relative rounded-lg border border-neutral-75"
      key={collection.providerCollection.id}
    >
      <div
        className="h-[166px] cursor-pointer rounded-t-lg bg-cover bg-center"
        style={{ backgroundImage: `url(${collection.headerMedia.url})` }}
        onClick={() => setSelectedCollection(collection)}
      >
        <div className="flex h-full w-full items-center justify-center rounded-t-lg bg-neutral-150 opacity-0 backdrop-brightness-50 transition hover:opacity-80">
          <span className="text-center text-white">
            <Button title="Preview" theme="secondary-white" />
          </span>
        </div>
      </div>

      <div className="mt-4 mb-2 flex flex-col justify-between overflow-hidden">
        <div className="px-4">
          <div className="mb-1 text-body font-bold text-neutral-150">
            {collection.providerCollection.name}
          </div>
          <div className="h-[140px] text-caption text-neutral-125 line-clamp-[7]">
            {collection.description}
          </div>
        </div>
        <div className="flex w-full flex-row items-center justify-between overflow-hidden pl-4 pr-2 pt-3">
          <div className="flex flex-row">
            <BookOpen className="relative h-4 w-4 text-neutral-150" />
            <span className="ml-1 text-small-caption font-bold">
              {collection.providerCollection.activities?.length ?? '0'}
            </span>
          </div>
          <CopyToLibraryButton
            collection={collection}
            theme="secondary-white"
            onAdded={onCollectionAdded}
          />
        </div>
      </div>
    </div>
  );
};

const CuratedProviderCollectionGrid: FC<{
  numCols?: 'three' | 'four';
  onCollectionAdded?: () => void;
}> = ({ numCols = 'four', onCollectionAdded }) => {
  const [selectedCollection, setSelectedCollection] =
    useState<CuratedProviderCollection | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { loading, data } = useCuratedProviderCollectionsQuery();

  const HomecomingSpinner = useHomecomingLoader(false);

  return (
    <>
      <div className="w-full">
        {loading ? (
          <div className="mt-32 flex h-full w-full items-center justify-center">
            {HomecomingSpinner}
          </div>
        ) : (
          <div
            className={classNames('grid w-full gap-2', {
              'grid-cols-3': numCols === 'three',
              'grid-cols-4': numCols === 'four',
            })}
          >
            {data?.curatedProviderCollections.map((collection) => (
              <CuratedProviderCollectionTile
                key={collection.id}
                collection={collection}
                setSelectedCollection={(collection) => {
                  setSelectedCollection(collection);
                  setIsModalOpen(true);
                }}
                onCollectionAdded={onCollectionAdded}
              />
            ))}
          </div>
        )}
      </div>
      <CuratedProviderCollectionInfoModal
        isOpen={isModalOpen}
        setClosed={() => setIsModalOpen(false)}
        collection={selectedCollection}
        onCollectionAdded={onCollectionAdded}
      />
    </>
  );
};

export default CuratedProviderCollectionGrid;
