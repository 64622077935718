import { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import homecomingLoaderAnimation from '../../assets/lotties/homecoming-loader.json';
import useIsMobileDevice from './useIsMobileDevice';
import classNames from 'classnames';
import { useAuth } from '../../contexts/AuthContext';
import { usePatientAuth } from '../../contexts/PatientAuthContext';

const GOLDEN_RATIO = 1.618;

export function useHomecomingLoader(fullScreenMode = true) {
  const homecomingLoaderContainerRef = useRef<HTMLDivElement>(null);
  const isMobileDevice = useIsMobileDevice();
  const { authedProviderUser } = useAuth();
  const { authedPatient } = usePatientAuth();
  const logoMediaUrl =
    authedProviderUser?.squareLogoMedia?.url ??
    authedPatient?.providerSquareLogo?.url;

  useEffect(() => {
    const homecomingLoaderContainer = homecomingLoaderContainerRef.current;
    if (homecomingLoaderContainer) {
      const animation = lottie.loadAnimation({
        container: homecomingLoaderContainer,
        animationData: homecomingLoaderAnimation,
        loop: false,
        autoplay: true,
      });

      animation.setSpeed(GOLDEN_RATIO);

      return () => {
        animation.destroy();
      };
    }
  }, []);

  return (
    <div
      className={classNames(
        fullScreenMode && 'flex h-screen items-center justify-center',
      )}
    >
      {logoMediaUrl ? (
        <div className="animate-ping">
          <img
            src={logoMediaUrl}
            alt="Custom Logo"
            className={classNames(
              'object-contain',
              isMobileDevice ? 'h-12 w-12' : 'h-16 w-16',
            )}
          />
        </div>
      ) : (
        <div
          className={classNames(isMobileDevice ? 'h-12 w-12' : 'h-24 w-24')}
          ref={homecomingLoaderContainerRef}
        />
      )}
    </div>
  );
}
