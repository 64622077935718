import PractitionerCard from './PractitionerCard';

const PractitionerList = ({ practitioners }) => (
  <>
    {practitioners.map((practitionerData, index) => (
      <PractitionerCard
        key={index}
        data={practitionerData}
        delay={index * 0.2}
      />
    ))}
  </>
);

export default PractitionerList;
