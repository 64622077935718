import React from 'react';
import { useForm } from 'react-hook-form';
import InputGroup from '../../../../components/InputGroup';
import TextAreaGroup from '../../../../components/TextAreaGroup';
import Checkbox from '../../../../components/Checkbox';
import EventParticipantsList from './EventParticipantsList';
import { CreateEventFormData } from './types';
import { SearchableCombobox } from './SearchableCombobox';
// import { useRoomResourceSearch } from '../hooks/useRoomResourceSearch';
import { useParticipantSearch } from '../hooks/useParticipantSearch';

interface EventFormProps {
  defaultValues: CreateEventFormData;
  onSubmit: (data: CreateEventFormData) => void;
  isAllDay: boolean;
}

const EventForm: React.FC<EventFormProps> = ({
  defaultValues,
  onSubmit,
  isAllDay,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreateEventFormData>({
    defaultValues,
  });

  const { searchParticipants } = useParticipantSearch();
  // const { searchRoomResources } = useRoomResourceSearch();

  const isAllDayChecked = watch('isAllDay', isAllDay);
  const participants = watch('participants', defaultValues.participants);

  const removeParticipant = (index: number) => {
    const updatedParticipants = participants.filter((_, i) => i !== index);
    setValue('participants', updatedParticipants);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <InputGroup
        label="Title"
        useNaturalLettering
        errorMessage={errors.title?.message}
        required
        inputSize="small"
        {...register('title', { required: 'Title is required' })}
      />
      <Checkbox
        id="isAllDay"
        labelContent="All day"
        className="flex items-center gap-2"
        checkboxClassName="text-green-150 focus:ring-secondary-50"
        {...register('isAllDay')}
      />
      <div className="grid grid-cols-2 gap-4">
        <InputGroup
          label="Start Date"
          useNaturalLettering
          type="date"
          errorMessage={errors.when?.startDate?.message}
          required
          inputSize="small"
          {...register('when.startDate', {
            required: 'Start date is required',
          })}
        />
        {isAllDayChecked && (
          <InputGroup
            label="End Date"
            type="date"
            useNaturalLettering
            errorMessage={errors.when?.endDate?.message}
            required
            inputSize="small"
            {...register('when.endDate', { required: isAllDayChecked })}
          />
        )}
      </div>
      {!isAllDayChecked && (
        <div className="grid grid-cols-2 gap-4">
          <InputGroup
            label="Start Time"
            useNaturalLettering
            type="time"
            errorMessage={errors.when?.startTime?.message}
            required
            inputSize="small"
            {...register('when.startTime', { required: !isAllDayChecked })}
          />
          <InputGroup
            label="End Time"
            useNaturalLettering
            type="time"
            errorMessage={errors.when?.endTime?.message}
            required
            inputSize="small"
            {...register('when.endTime', { required: !isAllDayChecked })}
          />
        </div>
      )}
      <SearchableCombobox
        control={control}
        name="participants"
        label="Participants"
        searchFunction={searchParticipants}
      />
      <EventParticipantsList
        participants={participants}
        onRemoveParticipant={(email, index) => removeParticipant(index)}
      />
      {/* TODO: Bring back this and a list of rooms/buildings */}
      {/* <SearchableCombobox
        control={control}
        name="resources"
        label="Rooms & Buildings"
        searchFunction={searchRoomResources}
      /> */}
      <TextAreaGroup
        label="Description"
        errorMessage={errors.description?.message}
        inputSize="small"
        {...register('description')}
      />
      <InputGroup
        label="Location"
        useNaturalLettering
        errorMessage={errors.location?.message}
        inputSize="small"
        {...register('location')}
      />
      {/* TODO: This button should not be in here, lets rework the callbacks to use the modal button instead */}
      <button
        type="submit"
        disabled={isSubmitting}
        className="group rounded-3xl bg-neutral-125 py-3 px-6 text-body text-white hover:bg-neutral-150 focus:outline-none focus:ring-2 focus:ring-secondary-50 disabled:cursor-not-allowed disabled:bg-neutral-125/75 disabled:text-white"
      >
        {defaultValues.title ? 'Update Event' : 'Create Event'}
      </button>
    </form>
  );
};

export default EventForm;
