import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import {
  useNylasCalendarEventsQuery,
  useNylasPrimaryCalendarQuery,
  useDeleteNylasCalendarEventMutation,
  useUpdateNylasCalendarMutation,
  NylasCalendarEventDataFragment,
} from '../../../../../generated/graphql';
import { customToast } from '../../../../components/ToastAlert/customToast';
import { DEFAULT_CALENDAR_HEX_COLOR } from '../../../../lib/colors';
import { whenToCalendarEvent } from '../utils/whenHelpers';
import { useResources } from './useResources';
import { logger } from '../../../../../lib/logger';
import { useCalendars } from './useCalendars';

export const useCalendarEvents = (
  isNylasConnected: boolean,
  selectedCalendarIds?: string[],
) => {
  const [currentDate, setCurrentDate] = useState(DateTime.now());
  const { getResourceByEmail } = useResources();
  const { getCalendarByEmail } = useCalendars();

  const {
    data: nylasPrimaryCalendarData,
    loading: isLoadingNylasPrimaryCalendar,
    refetch: refetchNylasPrimaryCalendar,
  } = useNylasPrimaryCalendarQuery({
    skip: !isNylasConnected,
  });

  const primaryCalendarId = nylasPrimaryCalendarData?.nylasPrimaryCalendar?.id;

  const {
    data: nylasCalendarEventsData,
    loading: isLoadingNylasCalendarEvents,
    refetch: refetchNylasCalendarEvents,
    networkStatus: calendarEventsNetworkStatus,
  } = useNylasCalendarEventsQuery({
    variables: {
      input: {
        calendarIds: selectedCalendarIds,
        startTime: currentDate.startOf('week'),
        endTime: currentDate.endOf('week'),
      },
    },
    skip: !isNylasConnected || !primaryCalendarId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const [updateNylasCalendar] = useUpdateNylasCalendarMutation();
  const [deleteNylasCalendarEvent, { loading: isDeletingEvent }] =
    useDeleteNylasCalendarEventMutation();

  const handleCalendarColorChange = async (
    calendarId: string,
    hexColor: string,
  ) => {
    try {
      const response = await updateNylasCalendar({
        variables: {
          input: { calendarId, hexColor },
        },
      });

      if (response.data?.updateNylasCalendar) {
        await refetchNylasPrimaryCalendar();
        customToast.success('Calendar color updated');
      }
    } catch (error) {
      customToast.error('Error updating calendar color');
    }
  };

  const handleDeleteEvent = async (event: NylasCalendarEventDataFragment) => {
    try {
      const response = await deleteNylasCalendarEvent({
        variables: {
          input: {
            eventId: event.id,
            calendarId: event.calendarId,
          },
        },
      });

      if (response.data?.deleteNylasCalendarEvent) {
        customToast.success('Event deleted');
        refetchNylasCalendarEvents();
        return true;
      }
    } catch (error) {
      customToast.error('Error deleting event');
    }
    return false;
  };

  const transformEventsForCalendar = () => {
    logger.debug('📅 Before transformation', {
      rawEvents: nylasCalendarEventsData?.nylasCalendarEvents,
    });

    const transformedEvents =
      nylasCalendarEventsData?.nylasCalendarEvents
        ?.map((event) => {
          const resource = getResourceByEmail(event.calendarId);
          const calendar = getCalendarByEmail(event.calendarId);
          const baseEventProps = {
            id: event.id,
            resourceId: resource?.id,
            title: event.title,
            backgroundColor: calendar?.hexColor || DEFAULT_CALENDAR_HEX_COLOR,
            // Enable FullCalendar editing
            editable: !event.readOnly,
            startEditable: !event.readOnly,
            durationEditable: !event.readOnly,
            /* Custom Properties */
            extendedProps: {
              originalEvent: event,
            },
          };

          return whenToCalendarEvent(event.when, baseEventProps);
        })
        .filter(Boolean) || [];

    logger.debug('📅 After transformation', {
      transformedEvents,
    });

    return transformedEvents;
  };

  const events = useMemo(
    () => transformEventsForCalendar(),
    [
      nylasCalendarEventsData?.nylasCalendarEvents,
      nylasPrimaryCalendarData?.nylasPrimaryCalendar?.id,
      nylasPrimaryCalendarData?.nylasPrimaryCalendar?.hexColor,
    ],
  );

  return {
    currentDate,
    setCurrentDate,
    events,
    isLoading:
      isLoadingNylasPrimaryCalendar ||
      isLoadingNylasCalendarEvents ||
      calendarEventsNetworkStatus === 4, // Refetching
    isDeletingEvent,
    primaryCalendar: nylasPrimaryCalendarData?.nylasPrimaryCalendar,
    handleCalendarColorChange,
    handleDeleteEvent,
    refetchEvents: refetchNylasCalendarEvents,
  };
};
