import React from 'react';
import Button from '../../components/Button';
import Tag from '../../svgs/Tag';

export const ManageTagsButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => (
  <Button
    title="Manage tags"
    theme="secondary"
    size="small"
    iconPosition="left"
    aria-label="Manage tags"
    IconComponent={Tag}
    onClick={onClick}
  />
);
