import { FC } from 'react';
import { PlusSmIcon } from '@heroicons/react/outline';

import Button from '../../components/Button';

const CreateACollectionButton: FC<{
  createProviderCollection: (
    addSelectedProviderActivityToCollection?: boolean,
  ) => void;
}> = ({ createProviderCollection }) => (
  <Button
    IconComponent={PlusSmIcon}
    noFocus
    size="small"
    iconPosition="left"
    theme="secondary"
    title="New collection"
    iconClassName="text-secondary-125 h-[20px] w-[20px] !mr-[6px]"
    className="mt-1 mb-3 w-[164px] justify-between rounded-md pl-[13px] text-left"
    onClick={() => createProviderCollection()}
  />
);

export default CreateACollectionButton;
