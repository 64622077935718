import { Transition } from '@headlessui/react';
import Avatar from '../../components/Avatar';
import ClientTable from './ClientTable';

const PractitionerCard = ({ data, delay }) => (
  <Transition
    className="mb-10 flex w-full flex-row justify-center"
    appear={true}
    show={true}
    enter="transform transition duration-500"
    enterFrom="opacity-0 -translate-y-5"
    enterTo="opacity-100 translate-y-0"
    style={{ transitionDelay: `${delay}s` }}
  >
    <div className="flex w-full flex-col overflow-x-auto rounded-lg border border-neutral-75">
      <div className="flex h-2 w-full rounded-t-lg bg-secondary-125"></div>
      <div className="m-4 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <Avatar
            size="medium-large"
            name={data.practitioner.name}
            imageUrl={data.practitioner.imageUrl}
            className="mr-3"
          />
          <div className="flex flex-col">
            <div className="caption font-sans font-medium text-green-150">
              {data.practitioner.name}
            </div>
            <div className="font-sans text-caption text-neutral-110">
              Practitioner
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="caption font-sans font-medium text-green-150">
            {data.practitioner.conversionRate}
          </div>
          <div className="font-sans text-caption text-neutral-150">
            Conversion rate
          </div>
        </div>
      </div>
      <ClientTable clients={data.clients} />
    </div>
  </Transition>
);

export default PractitionerCard;
