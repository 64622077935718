export type Period = 'week' | 'month' | 'year';

export const PERIOD_OPTIONS: { label: string; value: Period }[] = [
  { label: 'This week', value: 'week' },
  { label: 'This month', value: 'month' },
  { label: 'This year', value: 'year' },
];

export const PERFORMANCE_DATA = [
  {
    title: 'Sessions',
    number: 247,
    change: 20,
    isPositive: true,
  },
  { title: 'Alerts', number: 15, change: 4, isPositive: false },
  { title: 'Clients', number: 120, change: 5, isPositive: true },
  {
    title: 'Practitioners',
    number: 30,
    change: 4,
    isPositive: false,
  },
];

export const PRACTITIONERS_DATA = [
  {
    practitioner: {
      name: 'Ainsley Gee',
      imageUrl: 'https://randomuser.me/api/portraits/women/68.jpg',
      conversionRate: '37%',
    },
    clients: [
      {
        name: 'Ainsley Geemorniagno',
        alerts: 10,
        sessions: 2,
        ttfr: 5,
        started: 'Oct 1, 2024',
        tags: 'Tags, Tag2, Tag3...',
        imageUrl: 'https://randomuser.me/api/portraits/men/28.jpg',
      },
      // Add more clients as needed
    ],
  },
  {
    practitioner: {
      name: 'Liam Smith',
      imageUrl: 'https://randomuser.me/api/portraits/men/12.jpg',
      conversionRate: '82%',
    },
    clients: [
      {
        name: 'Olivia Brown',
        alerts: 5,
        sessions: 3,
        ttfr: 4,
        started: 'Sep 15, 2024',
        tags: 'Tag1, Tag4',
        imageUrl: 'https://randomuser.me/api/portraits/women/22.jpg',
      },
      {
        name: 'Noah Johnson',
        alerts: 2,
        sessions: 1,
        ttfr: 3,
        started: 'Aug 20, 2024',
        tags: 'Tag2, Tag5',
        imageUrl: 'https://randomuser.me/api/portraits/men/33.jpg',
      },
      // Add more clients as needed
    ],
  },
  {
    practitioner: {
      name: 'Emma Davis',
      imageUrl: 'https://randomuser.me/api/portraits/women/25.jpg',
      conversionRate: '67%',
    },
    clients: [
      {
        name: 'James Wilson',
        alerts: 8,
        sessions: 4,
        ttfr: 6,
        started: 'Jul 10, 2024',
        tags: 'Tag3, Tag6',
        imageUrl: 'https://randomuser.me/api/portraits/men/45.jpg',
      },
      {
        name: 'Sophia Martinez',
        alerts: 3,
        sessions: 2,
        ttfr: 2,
        started: 'Jun 5, 2024',
        tags: 'Tag1, Tag7',
        imageUrl: 'https://randomuser.me/api/portraits/women/37.jpg',
      },
      {
        name: 'Mason Anderson',
        alerts: 1,
        sessions: 5,
        ttfr: 7,
        started: 'May 25, 2024',
        tags: 'Tag2, Tag8',
        imageUrl: 'https://randomuser.me/api/portraits/men/50.jpg',
      },
      // Add more clients as needed
    ],
  },
  // Add more practitioners as needed
];
