import { FC, Fragment, useState } from 'react';
import { usePopper } from 'react-popper';
import { Menu } from '@headlessui/react';
import {
  DocumentDuplicateIcon,
  DotsHorizontalIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import IconButton from '../../../components/IconButton';
import MenuButton from '../../../components/MenuButton';
import PencilSquare from '../../../svgs/PencilSquare';
import { FollowUpTemplateDataFragment } from '../../../../generated/graphql';
import Spinner from '../../../svgs/Spinner';
import {
  AnalyticsComponent,
  AnalyticsPage,
  CommonAnalyticsEvent,
  trackProviderEvent,
} from '../../../../lib/analytics';

const FollowUpTemplateCardActionButton: FC<{
  followUpTemplate: FollowUpTemplateDataFragment;
  onClickRenameTemplate: (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => Promise<void>;
  onClickDuplicateTemplate: (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => Promise<void>;
  onClickDeleteTemplate: (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => Promise<void>;
}> = ({
  followUpTemplate,
  onClickRenameTemplate,
  onClickDuplicateTemplate,
  onClickDeleteTemplate,
}) => {
  const [isRenaming, setIsRenaming] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [menuReferenceElement, setMenuReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [menuPopperElement, setMenuPopperElement] =
    useState<HTMLDivElement | null>(null);

  const { styles: menuStyles, attributes: menuAttributes } = usePopper(
    menuReferenceElement,
    menuPopperElement,
    {
      placement: 'bottom-end',
      strategy: 'fixed',
      modifiers: [{ name: 'offset', options: { offset: [0, 2] } }],
    },
  );

  const handleRenameClick = async () => {
    try {
      setIsRenaming(true);
      await onClickRenameTemplate(followUpTemplate);
    } finally {
      setIsRenaming(false);
    }
  };

  const handleDuplicateClick = async () => {
    try {
      setIsDuplicating(true);
      await onClickDuplicateTemplate(followUpTemplate);
    } finally {
      setIsDuplicating(false);
    }
  };

  const handleDeleteClick = async () => {
    try {
      setIsDeleting(true);
      await onClickDeleteTemplate(followUpTemplate);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Menu>
      <Menu.Button as={Fragment}>
        <IconButton
          aria-label="Template actions"
          IconComponent={DotsHorizontalIcon}
          iconClassName="h-6 w-6 shrink-0"
          //   className="hover:bg-neutral-75"
          onClick={(e) => {
            e.stopPropagation();
            trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
              originPage: AnalyticsPage.FollowUpBuilder,
              originComponent: AnalyticsComponent.FollowUpDrawer,
              buttonName: 'Mail template actions',
            });
          }}
          ref={setMenuReferenceElement}
        />
      </Menu.Button>
      <Menu.Items
        as="div"
        ref={setMenuPopperElement}
        style={menuStyles.popper}
        {...menuAttributes.popper}
        className="w-64 divide-y divide-neutral-100 rounded-md bg-white shadow-400 focus:outline-none"
      >
        <MenuButton
          titleText="Rename"
          IconComponent={isRenaming ? Spinner : PencilSquare}
          className="rounded-t-md"
          onClick={(e) => {
            e.stopPropagation();
            handleRenameClick();
            // TODO: track event
          }}
          disabled={isRenaming}
        />
        <MenuButton
          titleText="Duplicate"
          IconComponent={isDuplicating ? Spinner : DocumentDuplicateIcon}
          onClick={(e) => {
            e.stopPropagation();
            handleDuplicateClick();
            // TODO: track event
          }}
          disabled={isDuplicating}
        />
        <MenuButton
          titleText="Delete"
          IconComponent={isDeleting ? Spinner : TrashIcon}
          textClassName="!text-red-100"
          iconClassName="!text-red-100"
          className="rounded-b-md"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick();
            // TODO: track event
          }}
          disabled={isDeleting}
        />
      </Menu.Items>
    </Menu>
  );
};

export default FollowUpTemplateCardActionButton;
