import { useCallback, useMemo } from 'react';
import { useNylasRoomResourcesQuery } from '../../../../../generated/graphql';
import { logger } from '../../../../../lib/logger';

export const useResources = () => {
  const { data, loading, error, refetch } = useNylasRoomResourcesQuery();

  const resources = useMemo(() => {
    logger.debug('📅 useResources memoizing resources:', {
      count: data?.nylasRoomResources?.length,
    });
    return data?.nylasRoomResources || [];
  }, [data?.nylasRoomResources]);

  return {
    resources,
    loading,
    error,
    refetch,
    getResourceById: useCallback(
      (id: string) => resources?.find((r) => r.id === id),
      [resources],
    ),
    getResourceByEmail: useCallback(
      (email: string) => resources?.find((r) => r.email === email),
      [resources],
    ),
  };
};
