export const LOGO_CONFIGS = {
  squareLogoMediaUrl: {
    graphqlField: 'squareLogoMedia',
    deleteField: 'deleteSquareLogoMedia',
    successMessage: 'Logo uploaded.',
    deleteMessage: 'Logo restored to Homecoming default',
    altText: 'Logo',
    description: 'Upload a logo for your organization.',
  },
  wordmarkLogoMediaUrl: {
    graphqlField: 'wordmarkLogoMedia',
    deleteField: 'deleteWordmarkLogoMedia',
    successMessage: 'Wordmark logo uploaded.',
    deleteMessage: 'Wordmark logo restored to Homecoming default',
    altText: 'Wordmark logo',
    description: 'Add your wordmark (logo + name) for your email branding.',
  },
  emailImageMediaUrl: {
    graphqlField: 'emailImageMedia',
    deleteField: 'deleteEmailImageMedia',
    successMessage: 'Email image uploaded.',
    deleteMessage: 'Email image restored to Homecoming default',
    altText: 'Email image',
    description: 'Choose an image to include in your emails.',
  },
} as const;
