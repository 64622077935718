import { useState, useEffect } from 'react';
import { PERFORMANCE_DATA, PRACTITIONERS_DATA } from './sampleData';

export const usePerformanceData = () => {
  const [data, setData] = useState<typeof PERFORMANCE_DATA | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setData(PERFORMANCE_DATA);
    }, Math.random() * 150 + 500); // Random timeout between 500ms and 1.5s

    return () => clearTimeout(timeout);
  }, []);

  return data;
};

export const usePractitionersData = () => {
  const [data, setData] = useState<typeof PRACTITIONERS_DATA | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setData(PRACTITIONERS_DATA);
    }, Math.random() * 1000 + 500); // Random timeout between 500ms and 1.5s

    return () => clearTimeout(timeout);
  }, []);

  return data;
};
