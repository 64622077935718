import React, { useState } from 'react';
import Button from '../../components/Button';
import { DownloadIcon } from '@heroicons/react/outline';
import Spinner from '../../svgs/Spinner';
import classNames from 'classnames';
import {
  exportClients,
  generateFilename,
  downloadFile,
} from '../../../api/rest/clients';
import { customToast } from '../../components/ToastAlert/customToast';

export const ExportClientsButton: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleExport = async () => {
    setIsLoading(true);
    try {
      const response = await exportClients();
      const filename = generateFilename();
      downloadFile(response, filename);
    } catch (error) {
      console.error('Error exporting client data:', error);
      customToast.error('Something went wrong.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      title={isLoading ? 'Exporting...' : 'Export'}
      theme="secondary"
      size="small"
      iconPosition="left"
      aria-label="Export clients"
      IconComponent={isLoading ? Spinner : DownloadIcon}
      className={classNames('ml-2 flex transition')}
      onClick={handleExport}
      disabled={isLoading}
      iconClassName={isLoading ? 'h-[16px] w-[16px]' : undefined}
    />
  );
};
