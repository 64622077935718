import { Portal } from '@headlessui/react';
import {
  CalendarIcon,
  ExternalLinkIcon,
  LocationMarkerIcon,
  MenuAlt2Icon,
  TrashIcon,
  UsersIcon,
  XIcon,
  PencilIcon,
  LockOpenIcon,
} from '@heroicons/react/outline';
import { NylasCalendarEventDataFragment } from '../../../../../generated/graphql';
import IconButton from '../../../../components/IconButton';
import Tooltip from '../../../../components/Tooltip';
import Avatar from '../../../../components/Avatar';
import Linkifier from '../../../../components/Linkifier';
import React from 'react';
import { usePopper } from 'react-popper';
import linkifyHtml from 'linkify-html';
import { formatWhen } from '../utils/whenHelpers';
import { logger } from '../../../../../lib/logger';

const preserveLineBreaks = (text: string) => {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
};

interface EventDetailsPopoverProps {
  event: NylasCalendarEventDataFragment;
  referenceElement: HTMLElement | null;
  isOpen: boolean;
  onClose: () => void;
  onDeleteEventClick: (event: NylasCalendarEventDataFragment) => void;
  onModifyEventClick: (event: NylasCalendarEventDataFragment) => void;
}

const EventDetailsPopover: React.FC<EventDetailsPopoverProps> = React.memo(
  ({
    event,
    referenceElement,
    isOpen,
    onClose,
    onDeleteEventClick,
    onModifyEventClick,
  }) => {
    const [popperElement, setPopperElement] =
      React.useState<HTMLElement | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: 'top-start',
    });

    if (!event || !isOpen) return null;

    const handleInteraction = (e: React.MouseEvent | React.TouchEvent) => {
      e.stopPropagation();
    };

    logger.debug('📅 EventDetailsPopover', {
      event,
    });

    return (
      <Portal>
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          onMouseDown={handleInteraction}
          onTouchStart={handleInteraction}
          className="z-50 my-1 w-96 overflow-hidden rounded-md bg-white px-5 py-4 shadow-400 focus:outline-none"
        >
          <div className="flex items-center justify-end space-x-2 border-neutral-75 pb-2">
            {event.readOnly !== true && (
              <>
                <IconButton
                  aria-label="Modify event"
                  IconComponent={PencilIcon}
                  iconClassName="h-4 w-4 text-neutral-125"
                  onClick={() => onModifyEventClick(event)}
                />
                <IconButton
                  aria-label="Delete event"
                  IconComponent={TrashIcon}
                  iconClassName="h-4 w-4 text-red-150"
                  onClick={() => onDeleteEventClick(event)}
                />
              </>
            )}
            <IconButton
              aria-label="Close"
              IconComponent={XIcon}
              iconClassName="h-4 w-4 text-neutral-125"
              onClick={onClose}
            />
          </div>

          <div className="flex max-h-[300px] w-full flex-col space-y-3 overflow-y-scroll">
            <div className="break-words text-category font-medium text-neutral-150">
              {event.title}
            </div>

            <div className="flex items-start">
              <CalendarIcon className="mr-3 h-5 w-5 flex-shrink-0 text-neutral-150" />
              <span className="text-caption text-neutral-150">
                {formatWhen(event.when)}
              </span>
            </div>

            {event.location && (
              <div className="flex items-start">
                <LocationMarkerIcon className="mr-3 h-5 w-5 flex-shrink-0 text-neutral-150" />
                <span className="text-caption text-neutral-150">
                  <Linkifier text={event.location} />
                </span>
              </div>
            )}

            {event.htmlLink && (
              <div className="flex items-start">
                <ExternalLinkIcon className="mr-3 h-5 w-5 flex-shrink-0 text-neutral-150" />
                <a
                  href={event.htmlLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-caption text-neutral-150"
                >
                  See event on external calendar
                </a>
              </div>
            )}

            {event.description && (
              <div className="flex w-full items-start">
                <MenuAlt2Icon className="mr-3 h-5 w-5 flex-shrink-0 text-neutral-150" />
                <div className="min-w-0 flex-1 break-words text-caption text-neutral-125">
                  {/* TODO: is linkifyHTML safe? */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(
                        preserveLineBreaks(event.description),
                      ),
                    }}
                  />
                </div>
              </div>
            )}

            {/* Visibility */}
            {event.visibility === 'public' && (
              <div className="flex items-start">
                <LockOpenIcon className="mr-3 h-5 w-5 flex-shrink-0 text-neutral-150" />
                <span className="text-caption text-neutral-150">Public</span>
              </div>
            )}

            {event.participants && event.participants.length > 0 && (
              <div className="flex items-start">
                <UsersIcon className="mr-3 mt-2 h-5 w-5 text-neutral-150" />
                <div className="flex flex-col space-y-2">
                  {event.organizer && (
                    <Tooltip
                      content={event.organizer.email}
                      enabled={Boolean(event.organizer.name)}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <Avatar
                          name={event.organizer.name || event.organizer.email}
                          size="small"
                        />
                        <div className="flex flex-col">
                          <div className="text-caption text-neutral-150">
                            {event.organizer.name || event.organizer.email}
                          </div>
                          <div className="text-small-caption text-neutral-125">
                            Organizer
                          </div>
                        </div>
                      </div>
                    </Tooltip>
                  )}
                  {event.participants.map((participant, index) => {
                    if (participant.email === event.organizer?.email) {
                      return null;
                    }
                    return (
                      <Tooltip
                        content={participant.email}
                        enabled={Boolean(participant.name)}
                        key={index}
                      >
                        <div className="flex flex-row items-center space-x-2">
                          <Avatar
                            name={participant.name || participant.email}
                            size="small"
                          />
                          <div className="text-caption text-neutral-150">
                            {participant.name || participant.email}
                          </div>
                        </div>
                      </Tooltip>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </Portal>
    );
  },
);

export default EventDetailsPopover;
