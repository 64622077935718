import classNames from 'classnames';
import { Menu } from '@headlessui/react';
import { SvgIconComponent } from '../types/svgs';
import IconButton from './IconButton';

const MenuButton: React.FC<{
  titleText: string;
  textClassName?: string;
  activeText?: string;
  disabled?: boolean;
  IconComponent?: SvgIconComponent;
  iconClassName?: string;
  iconPosition?: 'right' | 'left';
  helperText?: string;
  HelperIconComponent?: SvgIconComponent;
  HelperAvatarComponent?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
  iconButtonOnClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}> = ({
  titleText,
  textClassName,
  activeText,
  disabled = false,
  IconComponent,
  iconClassName,
  iconPosition = 'right',
  helperText,
  HelperIconComponent,
  HelperAvatarComponent,
  onClick,
  className,
  iconButtonOnClick,
}) => {
  const renderIcon = (active: boolean) => {
    if (!IconComponent) return null;

    const iconClasses = classNames(
      'h-[16px] w-[16px] flex-shrink-0',
      active && 'text-neutral-150',
      disabled && 'text-neutral-110',
      iconClassName,
    );

    if (iconButtonOnClick) {
      return (
        <IconButton
          className={iconClasses}
          onClick={iconButtonOnClick}
          IconComponent={IconComponent}
          iconClassName={iconClassName}
          aria-label={'Sub menu'}
        />
      );
    }

    return <IconComponent className={iconClasses} />;
  };

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={classNames(
            'flex w-full flex-row items-center px-3 py-4 text-neutral-125',
            active && 'bg-secondary-20',
            disabled && 'cursor-not-allowed text-neutral-110',
            iconPosition === 'right' && 'justify-between',
            className,
          )}
          onClick={onClick}
          disabled={disabled}
        >
          {IconComponent && iconPosition === 'left' && renderIcon(active)}
          <span
            className={classNames(
              'truncate text-caption text-neutral-150',
              active && 'text-neutral-150',
              activeText,
              iconPosition === 'left' && 'ml-2',
              textClassName,
            )}
          >
            {titleText}
          </span>
          <div className="ml-1.5 flex items-center">
            {(helperText || HelperIconComponent || HelperAvatarComponent) && (
              <div className="mr-2.5 flex items-center gap-x-1 text-small-action text-neutral-125">
                {HelperIconComponent && (
                  <HelperIconComponent className="h-4 w-4" />
                )}
                {HelperAvatarComponent && HelperAvatarComponent}
                {helperText}
              </div>
            )}
            {IconComponent && iconPosition === 'right' && renderIcon(active)}
          </div>
        </button>
      )}
    </Menu.Item>
  );
};

export default MenuButton;
