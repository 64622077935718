import { useCallback, useMemo } from 'react';
import { useNylasCalendarsQuery } from '../../../../../generated/graphql';
import { logger } from '../../../../../lib/logger';

export const useCalendars = () => {
  const { data, loading, error, refetch } = useNylasCalendarsQuery();

  const calendars = useMemo(() => {
    logger.debug('📅 useCalendars memoizing calendars:', {
      count: data?.nylasCalendars?.length,
    });
    return data?.nylasCalendars || [];
  }, [data?.nylasCalendars]);

  return {
    calendars,
    loading,
    error,
    refetch,
    getCalendarById: useCallback(
      (id: string) => calendars?.find((r) => r.id === id),
      [calendars],
    ),
    getCalendarByEmail: useCallback(
      (email: string) => calendars?.find((r) => r.email === email),
      [calendars],
    ),
  };
};
