import { FC, useState } from 'react';

import classNames from 'classnames';
import { usePopper } from 'react-popper';
import { XIcon } from '@heroicons/react/outline';
import { Listbox, Portal } from '@headlessui/react';
import { Controller, UseFormWatch, useFormContext } from 'react-hook-form';

import {
  PatientDataPermission,
  ProviderUserMemberDataFragment,
  ProviderUserRole,
} from '../../../../../../generated/graphql';

import {
  AddTeamMembersFormData,
  MemberField,
} from '../../../../../types/teamMembers';

import { emailRegisterOptions } from '../../../../../lib/register-options';

import ChevronRight from '../../../../../svgs/ChevronRight';

import InputGroup from '../../../../../components/InputGroup';
import IconButton from '../../../../../components/IconButton';

import {
  PatientDataPermissionOptions,
  ProviderUserRoleOptions,
  getLabelFromPatientDataPermission,
} from '../../TeamMembersTable/helpers';

interface TeamMemberInputRowProps {
  fieldIndex: number;
  formFields: MemberField[];
  handleRemoveRow: (index: number) => void;
  existingTeamMembers: ProviderUserMemberDataFragment[];
  watch: UseFormWatch<AddTeamMembersFormData>;
}

const TeamMemberInputRow: FC<TeamMemberInputRowProps> = ({
  fieldIndex,
  formFields,
  handleRemoveRow,
  existingTeamMembers,
  watch,
}) => {
  const {
    control,
    register,
    formState: { errors: formErrors },
  } = useFormContext();

  const emailKey: `members.${number}.email` = `members.${fieldIndex}.email`;
  const roleKey: `members.${number}.role` = `members.${fieldIndex}.role`;
  const patientDataPermissionKey: `members.${number}.patientDataPermission` = `members.${fieldIndex}.patientDataPermission`;

  const watchRole = watch(roleKey);

  const [roleElement, setRoleElement] = useState(null);
  const [rolePopperElement, setRolePopperElement] = useState(null);

  const { styles: roleStyles, attributes: roleAttributes } = usePopper(
    roleElement,
    rolePopperElement,
    {
      placement: 'bottom-end',
      strategy: 'fixed',
      modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
    },
  );

  const [patientDataPermissionElement, setPatientDataPermissionElement] =
    useState(null);

  const [
    patientDataPermissionElementPopperElement,
    setPatientDataPermissionElementPopperElement,
  ] = useState(null);

  const {
    styles: patientDataPermissionStyles,
    attributes: patientDataPermissionAttributes,
  } = usePopper(
    patientDataPermissionElement,
    patientDataPermissionElementPopperElement,
    {
      placement: 'bottom-end',
      strategy: 'fixed',
      modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
    },
  );

  const validateEmail = (email: string) => {
    if (
      formFields.some(
        (field, index) => index < fieldIndex && field.email === email,
      )
    ) {
      return 'Email has already been entered';
    }

    if (existingTeamMembers.some((member) => member.email === email)) {
      return 'Already on your team';
    }

    return true;
  };

  return (
    <div
      key={`team-member-input-row-${fieldIndex}`}
      className="flex w-full items-center gap-x-3"
    >
      <InputGroup
        label="Email"
        placeholder=""
        containerClassName="w-7/12"
        inputSize="small"
        labelHidden
        required
        errorMessage={formErrors.members?.[fieldIndex]?.email?.message}
        errorLocation="right"
        {...register(emailKey, {
          ...emailRegisterOptions,
          validate: validateEmail,
        })}
      />
      <Controller
        name={roleKey}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Listbox
            as="div"
            value={field.value}
            onChange={field.onChange}
            className="relative w-3/12"
          >
            {({ open }) => (
              <>
                <Listbox.Button
                  className={classNames(
                    'relative flex w-full flex-row items-center justify-between focus:ring-secondary-50',
                    'max-w-[24rem] rounded-md bg-neutral-50 p-3 text-left shadow-sm sm:text-caption',
                    'focus:outline-green-50 focus:ring-0 ',
                  )}
                  ref={setRoleElement}
                >
                  <div>{field.value}</div>
                  {field.value !== ProviderUserRole.Owner && (
                    <div className="flex items-center pl-2">
                      <ChevronRight
                        className={classNames(
                          open ? '-rotate-90' : 'rotate-90',
                        )}
                      />
                    </div>
                  )}
                </Listbox.Button>
                <Portal>
                  <Listbox.Options
                    className="absolute z-50 w-[24rem] divide-y divide-neutral-75 rounded-md border border-neutral-75 bg-white shadow-lg focus:outline-none"
                    ref={setRolePopperElement}
                    style={roleStyles.popper}
                    {...roleAttributes.popper}
                  >
                    {ProviderUserRoleOptions.map((roleOption, index) => (
                      <Listbox.Option
                        key={`roleOption_${roleOption.role}`}
                        value={roleOption.role}
                        className={({ active }) =>
                          classNames(
                            'cursor-pointer px-4 py-5',
                            active && 'bg-neutral-25',
                            {
                              'rounded-t-md': active && index === 0, // round top if it's the first item
                              'rounded-b-md':
                                active &&
                                index === ProviderUserRoleOptions.length - 1, // round bottom if it's the last item
                            },
                          )
                        }
                      >
                        <div className="text-body font-medium text-neutral-125">
                          {roleOption.role}
                        </div>
                        <div className="text-small-caption text-neutral-125">
                          {roleOption.description}
                        </div>
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Portal>
              </>
            )}
          </Listbox>
        )}
      />
      <Controller
        name={patientDataPermissionKey}
        control={control}
        rules={{ required: true }}
        render={({ field: patientDataPermissionField }) => (
          <Listbox
            as="div"
            value={patientDataPermissionField.value}
            onChange={patientDataPermissionField.onChange}
            className="relative w-4/12"
          >
            {({ open }) => (
              <>
                <Listbox.Button
                  className={classNames(
                    'relative flex w-full flex-row items-center justify-between',
                    'max-w-[24rem] rounded-md bg-neutral-50 p-3 text-left shadow-sm sm:text-caption',
                    'focus:outline-green-50 focus:ring-0',
                  )}
                  ref={setPatientDataPermissionElement}
                >
                  <div>
                    {
                      getLabelFromPatientDataPermission(
                        patientDataPermissionField.value,
                      ).label
                    }
                  </div>
                  <div className="flex items-center pl-2">
                    <ChevronRight
                      className={classNames(open ? '-rotate-90' : 'rotate-90')}
                    />
                  </div>
                </Listbox.Button>
                <Portal>
                  <Listbox.Options
                    className="absolute z-50 w-[24rem] divide-y divide-neutral-75 rounded-md border border-neutral-75 bg-white shadow-lg focus:outline-none"
                    ref={setPatientDataPermissionElementPopperElement}
                    style={patientDataPermissionStyles.popper}
                    {...patientDataPermissionAttributes.popper}
                  >
                    {PatientDataPermissionOptions.filter(
                      ({ patientDataPermission }) =>
                        watchRole !== ProviderUserRole.Practitioner ||
                        patientDataPermission !== PatientDataPermission.None,
                    ).map((patientDataPermission, index) => (
                      <Listbox.Option
                        key={`patientDataPermission_${patientDataPermission.patientDataPermission}`}
                        value={patientDataPermission.patientDataPermission}
                        className={({ active }) =>
                          classNames(
                            'cursor-pointer px-4 py-5',
                            active && 'bg-neutral-25',
                            {
                              'rounded-t-md': active && index === 0, // round top if it's the first item
                              'rounded-b-md':
                                active &&
                                index ===
                                  PatientDataPermissionOptions.length - 1, // round bottom if it's the last item
                            },
                          )
                        }
                      >
                        <div className="text-body font-medium text-neutral-125">
                          {patientDataPermission.label}
                        </div>
                        <div className="text-small-caption text-neutral-125">
                          {patientDataPermission.description}
                        </div>
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Portal>
              </>
            )}
          </Listbox>
        )}
      />

      <IconButton
        IconComponent={XIcon}
        aria-label="Remove row"
        iconClassName="h-4 w-4"
        className="rounded-lg p-2"
        disabled={formFields.length <= 1}
        onClick={() => handleRemoveRow(fieldIndex)}
      />
    </div>
  );
};

export default TeamMemberInputRow;
