import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusSmIcon } from '@heroicons/react/outline';

import { useAuth } from '../../../contexts/AuthContext';

import { isProviderUserOwnerOrAdminAuthorized } from '../../../lib/auth';

import Button from '../../components/Button';
import ServicesModule from '../../components/ServiceModule';
import PageContainer from '../../components/Containers/PageContainer';
import classNames from 'classnames';

const Services = () => {
  const navigate = useNavigate();
  const { authedProviderUser, showUpgradeBanner } = useAuth();

  useEffect(() => {
    if (!isProviderUserOwnerOrAdminAuthorized(authedProviderUser)) {
      navigate('/', { replace: true });
    }
  }, [authedProviderUser]);

  return (
    <PageContainer
      containerClassName={classNames(
        showUpgradeBanner
          ? 'h-[calc(100vh-var(--top-nav-height)-var(--upgrade-banner-height))] overflow-y-hidden'
          : 'h-[calc(100vh-var(--top-nav-height))] overflow-y-hidden',
      )}
    >
      <div className="h-full transition duration-500">
        <div className="mb-4 flex h-[48px] items-center justify-between">
          <div className="font-serif text-subtitle text-green-150">
            Services
          </div>
          <Button
            title="Add service"
            iconPosition="left"
            IconComponent={PlusSmIcon}
            onClick={() => navigate('/services/new')}
          />
        </div>
        <ServicesModule className="px-0 pt-4" />
      </div>
    </PageContainer>
  );
};

export default Services;
