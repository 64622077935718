import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import omit from 'lodash.omit';
import classNames from 'classnames';
import debounce from 'lodash.debounce';

import {
  ActivityType,
  ProviderCollection,
  ActivityDataFragment,
  useArchiveActivityMutation,
  useProviderActivitiesQuery,
  useProviderCollectionsQuery,
  useArchiveActivitiesMutation,
  useDuplicateActivityMutation,
  useEditActivityTitleMutation,
  useAddActivityToProviderCollectionMutation,
} from '../../generated/graphql';

import { useAuth } from '../../contexts/AuthContext';

import { ColumnOrder } from '../types/tables';
import { ProviderActivityType } from '../types/activity';

import {
  ProviderActivityObject,
  getProviderActivityTitle,
  formatProviderActivityForFollowUp,
  isProviderActivityANonCustomAssessment,
} from '../lib/providerActivity';

import { pluralize } from '../lib/copy';
import { ContentType } from '../lib/followUp';
import { DEFAULT_TRANSITION_DURATION } from '../lib/animation';

import useFixPage from '../hooks/useFixPage';

import AddToLibraryMenu, {
  AddToLibraryMenuProps,
} from '../components/AddToLibraryMenu';

import {
  sortOptions,
  sortActivities,
  sortCollections,
  doesActivityMeetFilters,
  doesCollectionMeetFilters,
  getSelectedProviderActivitiesList,
} from '../components/Library/helpers';

import BottomButtons from '../components/BottomButtons';
import TableContainer from '../components/TableContainer';
import AddPDFModal from '../components/Modals/AddPDFModal';
import AddLinkModal from '../components/Modals/AddLinkModal';
import SearchAndFilters from '../components/SearchAndFilters';
import LibraryTable from '../components/Library/LibraryTable';
import AddImageModal from '../components/Modals/AddImageModal';
import AddAudioModal from '../components/Modals/AddAudioModal';
import PageContainer from '../components/Containers/PageContainer';
import { customToast } from '../components/ToastAlert/customToast';
import FileSettingsModal from '../components/Modals/FileSettingsModal';
import AddOrEditTextModal from '../components/Modals/AddOrEditTextModal';
import AllResourcesButton from '../components/Library/AllResourcesButton';
import RenameActivityModal from '../components/Modals/RenameActivityModal';
import ProviderCollectionsList from '../components/Library/ProviderCollectionsList';
import CreateACollectionButton from '../components/Library/CreateACollectionButton';
import CommunityResourcesButton from '../components/Library/CommunityResourcesButton';
import CuratedProviderCollectionGrid from '../components/CuratedProviderCollectionGrid';
import ProviderActivitySlideover from '../components/Library/ProviderActivitySlideover';
import ConfirmArchiveActivityModal from '../components/Modals/ConfirmArchiveActivityModal';
import ConfirmArchiveActivitiesModal from '../components/Modals/ConfirmArchiveActivitiesModal';
import ConfirmDeleteProviderCollectionModal from '../components/Library/Modals/ConfirmDeleteProviderCollectionModal';
import CreateOrUpdateProviderCollectionModal from '../components/Library/Modals/CreateOrUpdateProviderCollectionModal';
import LibraryTableContainer from '../components/Library/LibraryTableContainer';

const Library = () => {
  useFixPage();
  const navigate = useNavigate();
  const { showUpgradeBanner } = useAuth();

  const location = useLocation();
  const providerCollectionId = (
    location.state as { providerCollectionId?: string }
  )?.providerCollectionId;

  const [selectedProviderActivity, setSelectedProviderActivity] =
    useState<ActivityDataFragment>();

  const [isProviderActivitySlideoverOpen, setIsProviderActivitySlideoverOpen] =
    useState(false);

  const [isAddPDFModalOpen, setIsAddPDFModalOpen] = useState(false);
  const [isAddLinkModalOpen, setIsAddLinkModalOpen] = useState(false);
  const [isAddAudioModalOpen, setIsAddAudioModalOpen] = useState(false);
  const [isAddImageModalOpen, setIsAddImageModalOpen] = useState(false);
  const [isAddOrEditTextModalOpen, setIsAddOrEditTextModalOpen] =
    useState(false);
  const [isFileSettingsModalOpen, setIsFileSettingsModalOpen] = useState(false);

  const [
    isCreateOrUpdateProviderCollectionModalOpen,
    setIsCreateOrUpdateProviderCollectionModalOpen,
  ] = useState(false);

  const openCreateOrUpdateProviderCollectionModal = () =>
    setIsCreateOrUpdateProviderCollectionModalOpen(true);

  const [isCreatingNewProviderCollection, setIsCreatingNewProviderCollection] =
    useState(true);

  const [isArchivingResource, setIsArchivingResource] = useState(false);
  const [isArchivingResources, setIsArchivingResources] = useState(false);
  const [showActivityActionsMenu, setShowActivityActionsMenu] = useState(false);

  const [
    isConfirmDeleteCollectionModalOpen,
    setIsConfirmDeleteCollectionModalOpen,
  ] = useState(false);
  const [
    isConfirmArchiveActivityModalOpen,
    setIsConfirmArchiveActivityModalOpen,
  ] = useState(false);
  const [
    isConfirmArchiveActivitiesModalOpen,
    setIsConfirmArchiveActivitiesModalOpen,
  ] = useState(false);

  const [isRenameActivityModalOpen, setIsRenameActivityModalOpen] =
    useState(false);
  const [isRenamingActivity, setIsRenamingActivity] = useState(false);
  const [communityResourcesSelected, setCommunityResourcesSelected] =
    useState(false);

  const [archiveActivityMutation] = useArchiveActivityMutation();
  const [archiveActivitiesMutation] = useArchiveActivitiesMutation();
  const [duplicateActivityMutation] = useDuplicateActivityMutation();
  const [editActivityTitleMutation] = useEditActivityTitleMutation();
  const [addActivityToProviderCollection] =
    useAddActivityToProviderCollectionMutation();

  const {
    data: providerActivitiesData,
    error: providerActivitiesError,
    loading: providerActivitiesLoading,
    refetch: refetchProviderActivities,
  } = useProviderActivitiesQuery();

  const providerActivities = providerActivitiesData?.providerActivities;

  const {
    data: providerCollectionsData,
    error: providerCollectionsError,
    refetch: refetchProviderCollections,
  } = useProviderCollectionsQuery();

  const providerCollections = providerCollectionsData?.providerCollections;

  const [selectedProviderCollection, setSelectedProviderCollection] = useState<
    ProviderCollection | undefined
  >();

  // Auto-selects a provider collection if ID sent in location state
  useEffect(() => {
    if (providerCollectionId && providerCollections) {
      setSelectedProviderCollection(
        providerCollections.find(
          (providerCollection) =>
            providerCollection.id === providerCollectionId,
        ),
      );
    }
  }, [
    providerCollectionId,
    providerCollections,
    setSelectedProviderCollection,
  ]);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermValue, setSearchTermValue] = useState('');

  const [contentTypeFilter, setContentTypeFilter] =
    useState<ContentType | null>(null);

  const [activityOrder, setActivityOrder] = useState<ColumnOrder>(
    sortOptions.newestCreated,
  );

  const debouncedSearch = useCallback(
    debounce(
      (e) => setSearchTerm(e.target.value.toLowerCase().trim()),
      DEFAULT_TRANSITION_DURATION,
    ),
    [],
  );

  const openAddOrEditTextModal = () => setIsAddOrEditTextModalOpen(true);

  const hasContentTypeFilter = contentTypeFilter !== null;

  const providerActivitiesList = sortActivities(
    providerActivities?.filter((providerActivity) =>
      doesActivityMeetFilters(providerActivity, {
        searchTerm,
        contentTypeFilter,
        selectedProviderCollection,
      }),
    ),
    activityOrder !== null ? activityOrder : sortOptions.newestCreated,
  );

  const sortedProviderCollections = sortCollections(
    providerCollections?.filter((providerCollection) =>
      doesCollectionMeetFilters(providerCollection, {
        searchTerm,
        contentTypeFilter,
        selectedProviderCollection,
      }),
    ),
    activityOrder !== null ? activityOrder : sortOptions.newestCreated,
  );

  useEffect(() => {
    if (providerActivitiesError) {
      customToast.error('Unable to fetch library items.');
    }
  }, [providerActivitiesError]);

  useEffect(() => {
    if (providerCollectionsError) {
      customToast.error('Unable to fetch library collections.');
    }
  }, [providerCollectionsError]);

  const clearSearchTerm = () => {
    setSearchTermValue('');
    setSearchTerm('');
  };

  const showEmptyTableFilterState = Boolean(
    !providerActivitiesLoading &&
      providerActivitiesList?.length === 0 &&
      sortedProviderCollections?.length === 0,
  );

  const archiveProviderResource = async () => {
    if (!selectedProviderActivity) return;

    try {
      setIsArchivingResource(true);
      await archiveActivityMutation({
        variables: {
          activityId: selectedProviderActivity.id,
          activityType: selectedProviderActivity.activityType,
        },
      });
      customToast.success('Resource deleted.');
      await refetchProviderActivities?.();
    } catch (error) {
      customToast.error('Unable to delete resource.');
    } finally {
      setIsArchivingResource(false);
      setIsConfirmArchiveActivityModalOpen(false);
      setIsProviderActivitySlideoverOpen(false);
    }
  };

  const duplicateActivity = async () => {
    if (!selectedProviderActivity) return;

    try {
      await duplicateActivityMutation({
        variables: {
          input: {
            activityId: selectedProviderActivity.id,
            activityType: selectedProviderActivity.activityType,
          },
        },
      });
      customToast.success('Resource duplicated.');
      await refetchProviderActivities?.();
    } catch (error) {
      customToast.error(
        'Unable to duplicate resource. Please try again later.',
      );
    }
  };

  const renameActivity = async (newTitle: string) => {
    if (!selectedProviderActivity) return;

    try {
      setIsRenamingActivity(true);
      await editActivityTitleMutation({
        variables: {
          input: {
            activityId: selectedProviderActivity.id,
            activityType: selectedProviderActivity.activityType,
            title: newTitle,
          },
        },
      });
      customToast.success('Resource renamed.');
      setIsRenamingActivity(false);
      setIsRenameActivityModalOpen(false);
      await refetchProviderActivities?.();
    } catch (error) {
      setIsRenamingActivity(false);
      setIsRenameActivityModalOpen(false);
      customToast.error('Unable to rename resource.');
    }
  };

  const [
    addSelectedProviderActivityToCollection,
    setAddSelectedProviderActivityToCollection,
  ] = useState(false);

  const createProviderCollection = (
    addSelectedProviderActivityToCollection?: boolean,
  ) => {
    setIsCreatingNewProviderCollection(true);
    openCreateOrUpdateProviderCollectionModal();
    addSelectedProviderActivityToCollection &&
      setAddSelectedProviderActivityToCollection(true);
  };

  const renameProviderCollection = () => {
    setIsCreatingNewProviderCollection(false);
    openCreateOrUpdateProviderCollectionModal();
  };

  const clearFilterTerm = () => setContentTypeFilter(null);

  const clearSearchAndFilters = () => {
    clearSearchTerm();
    clearFilterTerm();
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTermValue(e.target.value);
    debouncedSearch(e);
  };

  const hasEmptyProviderCollection = Boolean(
    selectedProviderCollection &&
      providerActivitiesList?.length === 0 &&
      !hasContentTypeFilter &&
      !searchTerm,
  );

  const [
    selectedProviderActivitiesObject,
    setSelectedProviderActivitiesObject,
  ] = useState<ProviderActivityObject>({});

  const selectedProviderActivitiesList = getSelectedProviderActivitiesList(
    selectedProviderActivitiesObject,
  );

  const clearSelectedProviderActivities = () =>
    setSelectedProviderActivitiesObject({});

  const showBottomButtons = Boolean(selectedProviderActivitiesList.length);

  const selectedProviderActivitiesOmitNonCustomAssessments =
    selectedProviderActivitiesList.filter(
      (activity) => !isProviderActivityANonCustomAssessment(activity),
    );

  const bottomButtonActions = [
    {
      title: `Delete ${pluralize(
        selectedProviderActivitiesOmitNonCustomAssessments.length,
        'resource',
        'resources',
      )} `,
      theme: 'destructive' as const,
      onClick: () => {
        setIsConfirmArchiveActivitiesModalOpen(true);
      },
      disabled: selectedProviderActivitiesOmitNonCustomAssessments.length === 0,
    },
    {
      title: `Send ${pluralize(
        selectedProviderActivitiesList.length,
        'resource',
        'resources',
      )}`,
      theme: 'secondary' as const,
      onClick: () => {
        sendProviderActivitiesInFollowUp(selectedProviderActivitiesList);
      },
    },
  ];

  const archiveProviderResources = async (
    providerActivities: ActivityDataFragment[],
  ): Promise<void> => {
    try {
      setIsArchivingResources(true);
      const { data } = await archiveActivitiesMutation({
        variables: {
          input: {
            activities: providerActivities
              .filter(
                (activity) => !isProviderActivityANonCustomAssessment(activity),
              )
              .map((activity) => ({
                activityId: activity.id,
                activityType: activity.activityType,
              })),
          },
        },
      });
      if (data?.archiveActivities) {
        customToast.success(
          `${pluralize(
            data.archiveActivities.length,
            'resource',
            'resources',
          )} deleted.`,
        );
        await refetchProviderActivities?.();
      }
    } catch (error) {
      customToast.error('Unable to delete resources.');
    } finally {
      setIsArchivingResources(false);
      setIsConfirmArchiveActivityModalOpen(false);
      setIsProviderActivitySlideoverOpen(false);
      clearSelectedProviderActivities();
    }
  };

  const sendProviderActivitiesInFollowUp = (
    providerActivities: ActivityDataFragment[],
  ): void => {
    const formattedProviderActivities = providerActivities?.map(
      (providerActivity, index) =>
        formatProviderActivityForFollowUp(providerActivity, index),
    );

    navigate(`/follow-ups/new`, {
      state: {
        initialActivities: formattedProviderActivities,
      },
    });
  };

  // Bind callbacks to the Add to Library button, so we don't have to pass them
  // all over the place
  const BoundAddToLibrary: FC<AddToLibraryMenuProps> = (props) => {
    return (
      <AddToLibraryMenu
        containerClassName="z-[40]"
        onClickAddLink={() => setIsAddLinkModalOpen(true)}
        onClickAddImage={() => setIsAddImageModalOpen(true)}
        onClickAddPDF={() => setIsAddPDFModalOpen(true)}
        onClickAddAudio={() => setIsAddAudioModalOpen(true)}
        onClickAddForm={() =>
          navigate('/library/forms/new', {
            state: {
              ...(selectedProviderCollection && {
                fromProviderCollectionId: selectedProviderCollection.id,
              }),
              fromPage: 'library',
            },
          })
        }
        onClickAddText={openAddOrEditTextModal}
        {...props}
      />
    );
  };

  const refetchAll = async () => {
    await Promise.all([
      refetchProviderCollections(),
      refetchProviderActivities(),
    ]);
  };

  const onActivityAdded = (activityType: ActivityType) => {
    return async (activityId: string) => {
      if (selectedProviderCollection) {
        await addActivityToProviderCollection({
          variables: {
            input: {
              activityId,
              activityType,
              providerCollectionId: selectedProviderCollection.id,
            },
          },
        });

        await refetchAll();
      }
    };
  };

  return (
    <>
      <PageContainer
        show={Boolean(providerActivities)}
        loading={providerActivitiesLoading}
        onClick={() => setShowActivityActionsMenu(false)}
        containerClassName={classNames(
          'pb-0',
          showUpgradeBanner
            ? 'h-[calc(100vh-var(--top-nav-height)-var(--upgrade-banner-height))] overflow-y-hidden'
            : 'h-[calc(100vh-var(--top-nav-height))] overflow-y-hidden',
        )}
      >
        <>
          <div className="mb-4 flex flex-row justify-between">
            <div
              className="cursor-pointer font-serif text-subtitle text-green-150"
              onClick={() => {
                setSelectedProviderCollection(undefined);
              }}
            >
              Library
            </div>
            <BoundAddToLibrary />
          </div>
          <div className="mb-6 flex h-[48px] w-full flex-row items-center justify-between">
            <SearchAndFilters
              searchTermValue={searchTermValue}
              clearSearchTerm={clearSearchTerm}
              clearFilterTerm={clearFilterTerm}
              onChangeSearch={onChangeSearch}
              contentTypeFilter={contentTypeFilter}
              setContentTypeFilter={setContentTypeFilter}
            />
          </div>
          <TableContainer>
            <LibraryTableContainer
              columnContent={
                <>
                  <AllResourcesButton
                    selectedProviderCollection={selectedProviderCollection}
                    setSelectedProviderCollection={() => {
                      setSelectedProviderCollection(undefined);
                      setCommunityResourcesSelected(false);
                    }}
                    communityResourcesSelected={communityResourcesSelected}
                    followUpMode={false}
                  />
                  <CommunityResourcesButton
                    communityResourcesSelected={communityResourcesSelected}
                    selectCommunityResources={() => {
                      setSelectedProviderCollection(undefined);
                      setCommunityResourcesSelected(true);
                    }}
                  />
                  <CreateACollectionButton
                    createProviderCollection={createProviderCollection}
                  />
                  <ProviderCollectionsList
                    providerCollections={providerCollections}
                    selectedProviderCollection={selectedProviderCollection}
                    setSelectedProviderCollection={(collection) => {
                      setSelectedProviderCollection(collection);
                      setCommunityResourcesSelected(false);
                    }}
                    clearSearchAndFilters={clearSearchAndFilters}
                  />
                </>
              }
              libraryContent={
                <>
                  {communityResourcesSelected ? (
                    <div className="w-full p-5">
                      <CuratedProviderCollectionGrid
                        numCols="three"
                        onCollectionAdded={async () => {
                          await refetchAll();
                        }}
                      />
                    </div>
                  ) : (
                    <LibraryTable
                      activityOrder={activityOrder}
                      providerActivities={providerActivitiesList}
                      selectedProviderCollection={selectedProviderCollection}
                      setActivityOrder={setActivityOrder}
                      renameProviderCollection={renameProviderCollection}
                      setIsConfirmDeleteCollectionModalOpen={
                        setIsConfirmDeleteCollectionModalOpen
                      }
                      setIsConfirmArchiveActivityModalOpen={
                        setIsConfirmArchiveActivityModalOpen
                      }
                      duplicateActivity={duplicateActivity}
                      setIsRenameActivityModalOpen={
                        setIsRenameActivityModalOpen
                      }
                      setIsFileSettingsModalOpen={setIsFileSettingsModalOpen}
                      setSelectedProviderActivity={setSelectedProviderActivity}
                      setSelectedProviderCollection={
                        setSelectedProviderCollection
                      }
                      setIsProviderActivitySlideoverOpen={
                        setIsProviderActivitySlideoverOpen
                      }
                      refetchProviderActivities={refetchProviderActivities}
                      showEmptyTableFilterState={showEmptyTableFilterState}
                      hasEmptyProviderCollection={hasEmptyProviderCollection}
                      clearSearchAndFilters={clearSearchAndFilters}
                      createProviderCollection={createProviderCollection}
                      sortedProviderCollections={sortedProviderCollections}
                      allProviderCollections={providerCollections}
                      openAddOrEditTextModal={openAddOrEditTextModal}
                      selectedProviderActivity={selectedProviderActivity}
                      showActivityActionsMenu={showActivityActionsMenu}
                      setShowActivityActionsMenu={setShowActivityActionsMenu}
                      sendProviderActivitiesInFollowUp={
                        sendProviderActivitiesInFollowUp
                      }
                      selectedProviderActivitiesObject={
                        selectedProviderActivitiesObject
                      }
                      setSelectedProviderActivitiesObject={
                        setSelectedProviderActivitiesObject
                      }
                      selectedProviderActivitiesList={
                        selectedProviderActivitiesList
                      }
                      contentTypeFilter={contentTypeFilter}
                      searchTerm={searchTerm}
                      BoundAddToLibrary={BoundAddToLibrary}
                      allProviderActivities={providerActivities}
                    />
                  )}
                </>
              }
            />
          </TableContainer>
        </>
      </PageContainer>

      <AddLinkModal
        isModalOpen={isAddLinkModalOpen}
        setClosed={() => setIsAddLinkModalOpen(false)}
        refetchProviderActivities={refetchProviderActivities}
        onAdded={onActivityAdded(ActivityType.Link)}
      />
      <AddPDFModal
        isModalOpen={isAddPDFModalOpen}
        setClosed={() => setIsAddPDFModalOpen(false)}
        refetchProviderActivities={refetchProviderActivities}
        onAdded={onActivityAdded(ActivityType.Pdf)}
      />
      <AddImageModal
        isModalOpen={isAddImageModalOpen}
        setClosed={() => setIsAddImageModalOpen(false)}
        refetchProviderActivities={refetchProviderActivities}
        onAdded={onActivityAdded(ActivityType.Image)}
      />
      <AddAudioModal
        isModalOpen={isAddAudioModalOpen}
        setClosed={() => setIsAddAudioModalOpen(false)}
        refetchProviderActivities={refetchProviderActivities}
        onAdded={onActivityAdded(ActivityType.Audio)}
      />
      <AddOrEditTextModal
        isModalOpen={isAddOrEditTextModalOpen}
        setClosed={() => setIsAddOrEditTextModalOpen(false)}
        clearModalData={() => setSelectedProviderActivity(undefined)}
        refetchProviderActivities={refetchProviderActivities}
        selectedActivityText={
          selectedProviderActivity?.__typename === ProviderActivityType.Text
            ? selectedProviderActivity
            : undefined
        }
        onAdded={onActivityAdded(ActivityType.Text)}
      />
      <ProviderActivitySlideover
        isOpen={isProviderActivitySlideoverOpen}
        onClose={() => setIsProviderActivitySlideoverOpen(false)}
        selectedProviderActivity={selectedProviderActivity}
        setIsConfirmArchiveActivityModalOpen={
          setIsConfirmArchiveActivityModalOpen
        }
      />
      <CreateOrUpdateProviderCollectionModal
        isModalOpen={isCreateOrUpdateProviderCollectionModalOpen}
        setClosed={() => setIsCreateOrUpdateProviderCollectionModalOpen(false)}
        refetchProviderCollections={refetchProviderCollections}
        isCreatingNewProviderCollection={isCreatingNewProviderCollection}
        selectedProviderCollection={selectedProviderCollection}
        setSelectedProviderCollection={setSelectedProviderCollection}
        selectedProviderActivity={selectedProviderActivity}
        addSelectedProviderActivityToCollection={
          addSelectedProviderActivityToCollection
        }
        setAddSelectedProviderActivityToCollection={
          setAddSelectedProviderActivityToCollection
        }
        refetchProviderActivities={refetchProviderActivities}
      />
      <ConfirmArchiveActivityModal
        isModalOpen={isConfirmArchiveActivityModalOpen}
        setClosed={() => setIsConfirmArchiveActivityModalOpen(false)}
        onConfirm={async () => {
          await archiveProviderResource();

          if (selectedProviderActivity) {
            const remainingSelectedProviderActivities = omit(
              selectedProviderActivitiesObject,
              [selectedProviderActivity.id],
            );

            setSelectedProviderActivitiesObject?.(
              remainingSelectedProviderActivities,
            );
          }
        }}
        isArchivingResource={isArchivingResource}
        activityType={selectedProviderActivity?.activityType}
      />
      <ConfirmArchiveActivitiesModal
        isModalOpen={isConfirmArchiveActivitiesModalOpen}
        setClosed={() => setIsConfirmArchiveActivitiesModalOpen(false)}
        isArchivingResources={isArchivingResources}
        selectedProviderActivitiesListLength={
          selectedProviderActivitiesOmitNonCustomAssessments.length
        }
        onConfirm={async () => {
          await archiveProviderResources(selectedProviderActivitiesList);
          setSelectedProviderActivitiesObject?.({});
          setIsConfirmArchiveActivitiesModalOpen(false);
        }}
      />
      <RenameActivityModal
        isModalOpen={isRenameActivityModalOpen}
        setClosed={() => setIsRenameActivityModalOpen(false)}
        clearModalData={() => setSelectedProviderActivity(undefined)}
        onSave={renameActivity}
        isRenamingActivity={isRenamingActivity}
        activityName={
          selectedProviderActivity
            ? getProviderActivityTitle(selectedProviderActivity) ?? ''
            : ''
        }
      />
      <FileSettingsModal
        isModalOpen={isFileSettingsModalOpen}
        setClosed={() => setIsFileSettingsModalOpen(false)}
        activityPdf={
          selectedProviderActivity?.__typename === ProviderActivityType.PDF
            ? selectedProviderActivity
            : undefined
        }
        onUpdated={() => refetchProviderActivities()}
      />
      <ConfirmDeleteProviderCollectionModal
        isModalOpen={isConfirmDeleteCollectionModalOpen}
        setClosed={() => setIsConfirmDeleteCollectionModalOpen(false)}
        selectedProviderCollection={selectedProviderCollection}
        onDeleted={refetchAll}
        setSelectedProviderCollection={setSelectedProviderCollection}
      />
      <BottomButtons
        isVisible={showBottomButtons}
        actions={bottomButtonActions}
      />
    </>
  );
};

export default Library;
