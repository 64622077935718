import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useAuth } from '../../../../contexts/AuthContext';

import { useSendTestProviderEmailMutation } from '../../../../generated/graphql';

import Spinner from '../../../svgs/Spinner';
import { DEFAULT_COLORS } from '../../../lib/colors';

import Button from '../../../components/Button';
import { customToast } from '../../../components/ToastAlert/customToast';

import ColorInput from './ColorInput';
import type { AppearanceForm } from './types';
import { LogoUploadSection } from './LogoUploadSection';
import { useAppearanceHandlers } from './useAppearanceHandlers';

const AppearanceSection: FC = () => {
  const { authedProviderUser, refreshAuthedProviderUser } = useAuth();

  const [sendTestProviderEmail] = useSendTestProviderEmailMutation();

  const { watch, register, setValue } = useForm<AppearanceForm>({
    mode: 'onSubmit',
    defaultValues: {
      primaryColor: authedProviderUser?.primaryColor || DEFAULT_COLORS.PRIMARY,
      secondaryColor:
        authedProviderUser?.secondaryColor || DEFAULT_COLORS.SECONDARY,
      squareLogoMediaUrl: authedProviderUser?.squareLogoMedia?.url,
      wordmarkLogoMediaUrl: authedProviderUser?.wordmarkLogoMedia?.url,
      emailImageMediaUrl: authedProviderUser?.emailImageMedia?.url,
    },
  });

  const { updateColor, handleLogoUpload, handleLogoDelete, isLogoUploading } =
    useAppearanceHandlers(setValue, refreshAuthedProviderUser);

  const watchPrimaryColor = watch('primaryColor');
  const watchSecondaryColor = watch('secondaryColor');
  const watchSquareLogo = watch('squareLogoMediaUrl');
  const watchWordmarkLogo = watch('wordmarkLogoMediaUrl');
  const watchEmailImage = watch('emailImageMediaUrl');

  useEffect(() => {
    if (authedProviderUser) {
      const {
        primaryColor,
        secondaryColor,
        squareLogoMedia,
        wordmarkLogoMedia,
        emailImageMedia,
      } = authedProviderUser;

      setValue('primaryColor', primaryColor ?? DEFAULT_COLORS.PRIMARY);
      setValue('secondaryColor', secondaryColor ?? DEFAULT_COLORS.SECONDARY);
      setValue('squareLogoMediaUrl', squareLogoMedia?.url);
      setValue('wordmarkLogoMediaUrl', wordmarkLogoMedia?.url);
      setValue('emailImageMediaUrl', emailImageMedia?.url);
    }
  }, [authedProviderUser, setValue]);

  const createColorRegister = (colorField: 'primaryColor' | 'secondaryColor') =>
    register(colorField, {
      pattern: {
        value: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
        message: 'Please enter a valid hex color code (e.g. #394642)',
      },
      onChange: async (e) => {
        let value = e.target.value;
        value = value.replace('#', '');
        value = '#' + value;
        setValue(colorField, value);

        if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
          await updateColor(value, colorField);
        }
      },
      onBlur: () => setValue(colorField, watch(colorField).trim()),
    });

  const primaryColorRegister = createColorRegister('primaryColor');
  const secondaryColorRegister = createColorRegister('secondaryColor');

  const [bannerEmailLoading, setBannerEmailLoading] = useState(false);
  const [notificationEmailLoading, setNotificationEmailLoading] =
    useState(false);

  const handleSendTestProviderEmail = async (isBanner: boolean) => {
    if (isBanner) {
      setBannerEmailLoading(true);
    } else {
      setNotificationEmailLoading(true);
    }

    try {
      await sendTestProviderEmail({ variables: { isBanner } });
      customToast.success(
        `${
          isBanner ? 'Banner' : 'Notification'
        } test email sent. Check your email!`,
      );
    } catch (error) {
      customToast.error('Error sending test email. Please try again later.');
    } finally {
      if (isBanner) {
        setBannerEmailLoading(false);
      } else {
        setNotificationEmailLoading(false);
      }
    }
  };

  return (
    <div className="flex w-full flex-col pl-[1px] pt-6 pb-2 mdlg:flex-row mdlg:gap-x-16">
      <div className="w-[400px]">
        <div className="mb-4 text-category font-medium">
          Platform customization
        </div>
        <ColorInput
          colorType="primary"
          description="Choose a darker color that contrasts well with white button text for readability across the platform."
          value={watchPrimaryColor}
          defaultValue={DEFAULT_COLORS.PRIMARY}
          register={primaryColorRegister}
          setValue={async (value) => {
            setValue('primaryColor', value);
            await updateColor(value, 'primaryColor');
          }}
        />
        <ColorInput
          colorType="secondary"
          description="Choose a mild color to subtly highlight selected items on the platform. This color should be soft enough to avoid distraction but still noticeable for emphasis."
          value={watchSecondaryColor}
          defaultValue={DEFAULT_COLORS.SECONDARY}
          register={secondaryColorRegister}
          setValue={async (value) => {
            setValue('secondaryColor', value);
            await updateColor(value, 'secondaryColor');
          }}
        />
        <LogoUploadSection
          title="Upload logo"
          logoType="squareLogoMediaUrl"
          logoPreview={watchSquareLogo}
          onUpload={handleLogoUpload}
          onDelete={handleLogoDelete}
          isUploading={isLogoUploading('squareLogoMediaUrl')}
        />
      </div>
      <div className="w-[400px]">
        <div className="mb-4 text-category font-medium">
          Email customization
        </div>
        <LogoUploadSection
          title="Upload wordmark"
          logoType="wordmarkLogoMediaUrl"
          logoPreview={watchWordmarkLogo}
          onUpload={handleLogoUpload}
          onDelete={handleLogoDelete}
          isUploading={isLogoUploading('wordmarkLogoMediaUrl')}
        />

        <LogoUploadSection
          title="Upload image"
          logoType="emailImageMediaUrl"
          logoPreview={watchEmailImage}
          onUpload={handleLogoUpload}
          onDelete={handleLogoDelete}
          isUploading={isLogoUploading('emailImageMediaUrl')}
        />

        <div className="mb-1 text-caption font-medium text-green-150">
          Test your branding assets
        </div>
        <div className="mb-4 text-small-caption text-neutral-125">
          Send yourself a test email to see how all of these look together.
        </div>
        <Button
          size="small"
          title="Send a banner test email"
          noFocus
          className="mb-3"
          onClick={async () => await handleSendTestProviderEmail(true)}
          {...(bannerEmailLoading && {
            IconComponent: Spinner,
            iconClassName: 'h-3.5 w-3.5',
          })}
        />
        <Button
          size="small"
          title="Send a notification test email"
          theme="secondary"
          noFocus
          onClick={async () => await handleSendTestProviderEmail(false)}
          {...(notificationEmailLoading && {
            IconComponent: Spinner,
            iconClassName: 'h-3.5 w-3.5',
          })}
        />
      </div>
    </div>
  );
};

export default AppearanceSection;
