import { FC, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

import {
  LinkIcon,
  DocumentIcon,
  PhotographIcon,
  ClipboardListIcon,
  PaperClipIcon,
} from '@heroicons/react/outline';

import { PlusSmIcon } from '@heroicons/react/outline';
import Headphones from '../svgs/Headphones';

import Button, { ButtonSize, ButtonTheme } from './Button';
import MenuButton from './MenuButton';
import classNames from 'classnames';
import { SvgIconComponent } from '../types/svgs';
import Signature from '../svgs/Signature';

export type AddToLibraryMenuProps = {
  buttonTheme?: ButtonTheme;
  buttonSize?: ButtonSize;
  iconPosition?: 'left' | 'right';
  iconClassName?: string;
  containerClassName?: string;
  buttonClassName?: string;
  menuItemsClassName?: string;
  title?: string;
  onClickAddLink?: React.MouseEventHandler<HTMLButtonElement>;
  onClickAddPDF?: React.MouseEventHandler<HTMLButtonElement>;
  onClickAddImage?: React.MouseEventHandler<HTMLButtonElement>;
  onClickAddAudio?: React.MouseEventHandler<HTMLButtonElement>;
  onClickAddForm?: React.MouseEventHandler<HTMLButtonElement>;
  onClickAddText?: React.MouseEventHandler<HTMLButtonElement>;
  fullWidth?: boolean;
  IconComponent?: SvgIconComponent;
};

const AddToLibraryMenu: FC<AddToLibraryMenuProps> = ({
  buttonTheme = 'primary',
  buttonSize,
  iconPosition = 'left',
  title = 'Add to library',
  iconClassName,
  buttonClassName,
  containerClassName,
  onClickAddLink,
  onClickAddImage,
  onClickAddPDF,
  onClickAddAudio,
  onClickAddForm,
  onClickAddText,
  menuItemsClassName,
  fullWidth = false,
  IconComponent = PlusSmIcon,
}) => {
  return (
    <div
      className={classNames(containerClassName, 'flex', {
        'w-full': fullWidth,
      })}
    >
      <Menu
        as="div"
        className={classNames('relative inline-block text-left', {
          'w-full': fullWidth,
        })}
      >
        <Menu.Button as={Fragment}>
          <Button
            title={title}
            theme={buttonTheme}
            size={buttonSize}
            IconComponent={IconComponent}
            iconPosition={iconPosition}
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={buttonClassName}
            iconClassName={iconClassName}
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classNames(
              'absolute right-0 z-[35] mt-2 w-64 origin-top-right divide-y divide-neutral-50 rounded-md  bg-white shadow-lg focus:outline-none',
              menuItemsClassName,
            )}
          >
            <MenuButton
              titleText="Link"
              className="rounded-t-md"
              IconComponent={LinkIcon}
              onClick={onClickAddLink}
            />
            <MenuButton
              titleText="Image"
              IconComponent={PhotographIcon}
              onClick={onClickAddImage}
            />
            <MenuButton
              titleText="Audio"
              IconComponent={Headphones}
              onClick={onClickAddAudio}
            />
            <MenuButton
              titleText="Text document"
              IconComponent={DocumentIcon}
              onClick={onClickAddText}
            />
            <MenuButton
              titleText="Form"
              IconComponent={ClipboardListIcon}
              onClick={onClickAddForm}
              helperText="E-sign option"
              HelperIconComponent={Signature}
            />
            <MenuButton
              titleText="File"
              IconComponent={PaperClipIcon}
              onClick={onClickAddPDF}
              className="rounded-b-md"
              helperText="E-sign option"
              HelperIconComponent={Signature}
            />
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default AddToLibraryMenu;
