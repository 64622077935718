import { FC, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import InputGroup from '../../../components/InputGroup';
import RoundedRectangle from '../../../svgs/RoundedRectangle';
import Button from '../../../components/Button';
import { debounce } from 'lodash';
import classNames from 'classnames';

interface ColorInputProps {
  colorType: 'primary' | 'secondary';
  description: string;
  value: string;
  defaultValue: string;
  register: any;
  setValue: (value: string) => void;
}

const ColorInput: FC<ColorInputProps> = ({
  colorType,
  description,
  value,
  defaultValue,
  register,
  setValue,
}) => {
  const [showPicker, setShowPicker] = useState(false);

  const debouncedColorChange = debounce((newColor: string) => {
    setValue(newColor);
  }, 300);

  const handlePickerClose = () => {
    setShowPicker(false);
  };

  const { onChange, ...rest } = register;

  return (
    <div className="relative mb-8 w-full transition-all">
      <div className={classNames('mb-1 text-caption font-medium')}>
        {colorType === 'primary' ? 'Primary' : 'Secondary'} color
      </div>
      <div className="mb-3 text-small-caption text-neutral-125">
        {description}
      </div>
      <div className="flex w-full flex-row items-center justify-between">
        <InputGroup
          IconLeft={RoundedRectangle}
          iconLeftProps={{ customColor: value }}
          iconLeftClassName="ml-1.5 cursor-pointer"
          className="rounded-lg pl-[54px]"
          containerClassName="w-[282px]"
          placeholder={defaultValue}
          defaultValue={defaultValue}
          maxLength={7}
          onFocus={(e) => {
            e.target.select();
            setShowPicker(true);
          }}
          {...rest}
          onChange={(e) => {
            onChange && onChange(e);
            showPicker && handlePickerClose();
          }}
        />
        {value !== defaultValue && (
          <Button
            title="Use default"
            className="!px-4"
            size="extra-small"
            onClick={() => setValue(defaultValue)}
          />
        )}
      </div>
      {showPicker && (
        <div className="absolute z-10 mt-2">
          <div className="fixed inset-0" onClick={handlePickerClose} />
          <HexColorPicker
            color={value}
            onChange={debouncedColorChange}
            className="min-h-[241.5px] min-w-[241.5px] rounded-lg shadow-lg"
          />
        </div>
      )}
    </div>
  );
};

export default ColorInput;
