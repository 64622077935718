import { useState, useCallback, RefObject, useEffect } from 'react';
import { CalendarViewType, ViewMode } from '../utils/enums';
import { logger } from '../../../../../lib/logger';
import FullCalendar from '@fullcalendar/react';
import { Calendar, Resource } from '../components/types';
import useLocalStorageValue from '../../../../hooks/useLocalStorageValue';
import {
  CALENDAR__SELECTED_CALENDAR_IDS,
  CALENDAR__SELECTED_RESOURCE_IDS,
} from '../../../../lib/storage';

export const useCalendarViewState = (
  calendarRef: RefObject<FullCalendar>,
  calendars?: Calendar[],
  resources?: Resource[],
) => {
  const [viewType, setViewType] = useState<CalendarViewType>(
    CalendarViewType.CALENDARS,
  );
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.TIME_GRID_WEEK);
  const [selectedCalendarIds, setSelectedCalendarIds] = useLocalStorageValue<
    string[]
  >(CALENDAR__SELECTED_CALENDAR_IDS);
  const [selectedResourceIds, setSelectedResourceIds] = useLocalStorageValue<
    string[]
  >(CALENDAR__SELECTED_RESOURCE_IDS);

  // Auto-select primary calendar when calendars are loaded and no selection in storage
  useEffect(() => {
    if (!calendars?.length) return;

    // Local storage might not be set yet, so we need to make sure we have an array
    const selectedIds = selectedCalendarIds || [];

    // Filter out any selectedCalendarIds that do not exist in the current calendars
    const validCalendarIds = selectedIds.filter((id) =>
      calendars.some((calendar) => calendar.id === id),
    );

    if (validCalendarIds.length !== selectedIds.length) {
      logger.debug('📅 Filtering out non-existent calendars:', {
        invalidIds: selectedIds.filter((id) => !validCalendarIds.includes(id)),
      });
      setSelectedCalendarIds(validCalendarIds);
    }

    // Auto-select primary calendar if no valid selection exists
    if (validCalendarIds.length === 0) {
      const primaryCalendar = calendars.find((calendar) => calendar.isPrimary);
      if (primaryCalendar) {
        logger.debug('📅 Auto-selecting primary calendar:', {
          calendarId: primaryCalendar.id,
        });
        setSelectedCalendarIds([primaryCalendar.id]);
      }
    }
  }, [calendars, selectedCalendarIds, setSelectedCalendarIds]);

  // Auto-select first two resources when resources are loaded and no selection in storage
  useEffect(() => {
    if (!resources?.length) return;

    // Local storage might not be set yet, so we need to make sure we have an array
    const selectedIds = selectedResourceIds || [];

    // Filter out any selectedResourceIds that do not exist in the current resources
    const validResourceIds = selectedIds.filter((id) =>
      resources.some((resource) => resource.id === id),
    );

    if (validResourceIds.length !== selectedIds.length) {
      logger.debug('📅 Filtering out non-existent resources:', {
        invalidIds: selectedIds.filter((id) => !validResourceIds.includes(id)),
      });
      setSelectedResourceIds(validResourceIds);
    }

    // Auto-select first two resources if no valid selection exists
    if (validResourceIds.length === 0) {
      const firstTwoResources = resources.slice(0, 2);
      if (firstTwoResources.length) {
        logger.debug('📅 Auto-selecting first two resources:', {
          resourceIds: firstTwoResources.map((resource) => resource.id),
        });
        setSelectedResourceIds(
          firstTwoResources.map((resource) => resource.id),
        );
      }
    }
  }, [resources, selectedResourceIds, setSelectedResourceIds]);

  const handleViewTypeChange = useCallback(
    (newViewType: CalendarViewType) => {
      logger.debug('📅 Calendar View Type Changed:', {
        from: viewType,
        to: newViewType,
      });

      setViewType(newViewType);

      // Update view mode based on view type
      if (newViewType === CalendarViewType.RESOURCES) {
        // Force FullCalendar to update by using the API
        calendarRef.current
          ?.getApi()
          .changeView(ViewMode.RESOURCE_TIME_GRID_DAY);
        setViewMode(ViewMode.RESOURCE_TIME_GRID_DAY);
      } else {
        calendarRef.current?.getApi().changeView(ViewMode.TIME_GRID_WEEK);
        setViewMode(ViewMode.TIME_GRID_WEEK);
      }

      // Force re-render or events will not display as we are conditionally
      // displaying events based on viewType
      calendarRef.current?.render();
    },
    [viewType, calendarRef],
  );

  const handleCalendarSelect = useCallback((ids: string[]) => {
    logger.debug('📅 Selected Calendars Changed:', { ids });
    setSelectedCalendarIds(ids);
  }, []);

  const handleResourceSelect = useCallback((ids: string[]) => {
    logger.debug('📅 Selected Resources Changed:', { ids });
    setSelectedResourceIds(ids);
  }, []);

  return {
    viewType,
    viewMode,
    selectedCalendarIds,
    selectedResourceIds,
    handleViewTypeChange,
    setViewMode,
    handleCalendarSelect,
    handleResourceSelect,
  };
};
