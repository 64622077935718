import classNames from 'classnames';
import Avatar from '../../components/Avatar';
import Tooltip from '../../components/Tooltip';
import { pluralize } from '../../lib/copy';

const ClientTable = ({ clients }) => (
  <table className="w-full">
    <thead>
      <tr
        className={classNames(
          'border-b border-neutral-75',
          'text-left text-small-caption font-medium text-neutral-150',
        )}
      >
        <th className="pl-4">Client</th>
        <th className="pl-0">Alerts</th>
        <th className="pl-0">Sessions</th>
        <th className="flex flex-row items-center pl-0">
          <Tooltip
            content={<>Time to First Reply</>}
            placement="bottom"
            showArrow
          >
            <span className="underline decoration-dotted underline-offset-4">
              TTFR
            </span>
          </Tooltip>
        </th>
        <th className="pl-0">Started</th>
        <th className="w-[320px] pl-0">Tags</th>
        <th className="w-[3.75rem]">{/* Overflow menu can go here */}</th>
      </tr>
    </thead>
    <tbody>
      <tr className="bg-neutral-30 font-medium text-neutral-125">
        <td className="pl-4 font-sans text-caption">
          {pluralize(clients.length, 'active client', 'active clients')}
        </td>
        <td className="pl-0">
          {clients.reduce((acc, client) => acc + client.alerts, 0)}
        </td>
        <td className="pl-0">
          {clients.reduce((acc, client) => acc + client.sessions, 0)}
        </td>
        <td className="pl-0">
          {clients.reduce((acc, client) => acc + client.ttfr, 0)}
        </td>
        <td colSpan={3} className="w-[3.75rem]">
          {/* Spacer */}
        </td>
      </tr>
      {clients.map((client, index) => (
        <tr
          key={index}
          className="whitespace-nowrap font-sans text-caption text-neutral-110"
        >
          <td className="w-1/6 py-4 pl-4">
            <div className="flex flex-row items-center gap-x-4">
              <Avatar
                size="medium-plus"
                name={client.name}
                imageUrl={client.imageUrl}
              />
              <span
                className="w-[220px] overflow-hidden text-ellipsis font-serif text-extra-small text-green-150"
                title={client.name}
              >
                {client.name}
              </span>
            </div>
          </td>
          <td className="pl-0">{client.alerts}</td>
          <td className="pl-0">{client.sessions}</td>
          <td className="pl-0">{client.ttfr}</td>
          <td className="pl-0">{client.started}</td>
          <td className="pl-0">{client.tags}</td>
          <td className="w-[3.75rem]">{/* Spacer */}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default ClientTable;
