import { useEffect } from 'react';
import {
  shouldUseProviderPrimaryColor,
  shouldUseProviderSecondaryColor,
} from '../lib/colors';
import { applyColorToRoot } from '../lib/colors';

interface UseProviderColorsProps {
  providerPrimaryColor: string | null | undefined;
  providerSecondaryColor: string | null | undefined;
}

export const useProviderColors = ({
  providerPrimaryColor,
  providerSecondaryColor,
}: UseProviderColorsProps) => {
  const useProviderPrimaryColor =
    shouldUseProviderPrimaryColor(providerPrimaryColor);
  const useProviderSecondaryColor = shouldUseProviderSecondaryColor(
    providerSecondaryColor,
  );

  useEffect(() => {
    useProviderPrimaryColor &&
      applyColorToRoot(providerPrimaryColor, 'primary');

    useProviderSecondaryColor &&
      applyColorToRoot(providerSecondaryColor, 'secondary');
  }, [providerPrimaryColor, providerSecondaryColor]);

  return {
    useProviderPrimaryColor,
    useProviderSecondaryColor,
  };
};
