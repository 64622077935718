import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { retrieveItem, storeItem } from '../../lib/storage';

const LIBRARY_PANEL_SIZE_KEY = 'libraryPanelSize';
const DEFAULT_PANEL_SIZE = 16;
const MIN_PANEL_SIZE = 16;
const MAX_PANEL_SIZE = 30;

type LibraryTableContainerProps = {
  columnContent?: React.ReactNode;
  libraryContent?: React.ReactNode;
};

export default function LibraryTableContainer({
  columnContent,
  libraryContent,
}: LibraryTableContainerProps) {
  const savedSize =
    retrieveItem<number>(LIBRARY_PANEL_SIZE_KEY) ?? DEFAULT_PANEL_SIZE;

  const handleResize = (sizes: number[]) => {
    storeItem(LIBRARY_PANEL_SIZE_KEY, sizes[0]);
  };

  return (
    <PanelGroup
      direction="horizontal"
      className="h-full w-full"
      onLayout={handleResize}
    >
      <Panel
        defaultSize={savedSize}
        minSize={MIN_PANEL_SIZE}
        maxSize={MAX_PANEL_SIZE}
        className="border-r border-neutral-75 md:min-w-[200px] lg:min-w-0"
      >
        {columnContent}
      </Panel>
      <PanelResizeHandle className="w-[2px] hover:bg-secondary-100" />
      <Panel>{libraryContent}</Panel>
    </PanelGroup>
  );
}
