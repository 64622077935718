import { FC, Fragment } from 'react';

import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { Popover } from '@headlessui/react';

import {
  NotificationDataFragment,
  useMarkAllProviderUserNotificationsReadMutation,
  useMarkProviderUserNotificationReadMutation,
} from '../../generated/graphql';

import { useAuth } from '../../contexts/AuthContext';

import { getTimeStamp } from '../lib/time';
import {
  getNotficationContent,
  handleNotificationClick,
} from '../lib/notifications';

import NotificationBell from '../svgs/NotificationBell';
import NotificationBellBadge from '../svgs/NotificationBellBadge';

import Avatar from './Avatar';
import Button from './Button';
import IconButton from './IconButton';
import {
  ProviderAnalyticsEvent,
  trackProviderEvent,
} from '../../lib/analytics';

const UnreadDot = () => (
  <div className="h-2 w-2 rounded-full bg-secondary-100" />
);

const NotificationItem: FC<{
  notification: NotificationDataFragment;
  onClick: (notification: NotificationDataFragment) => Promise<void>;
}> = ({ notification, onClick }) => {
  const NotificationContent = getNotficationContent(notification);

  return (
    <Popover.Button
      as="div"
      key={notification.id}
      onClick={() => onClick(notification)}
      className="flex w-full cursor-pointer flex-row items-center px-5 py-3 hover:bg-secondary-20 focus:bg-neutral-50 focus:outline-none"
    >
      <Avatar
        size="medium-plus"
        name={notification.patientName}
        imageUrl={notification.patientProfileImageMedia?.url}
        className="mr-3"
      />
      <div className="flex-grow">
        <div className="mb-1 text-caption text-green-150 line-clamp-2">
          {NotificationContent}
        </div>
        <div className="text-small-caption text-neutral-110">
          {getTimeStamp(notification.createdAt, true, true)}
        </div>
      </div>
      <div className="flex w-5 flex-none items-center justify-end">
        {!notification.readAt && <UnreadDot />}
      </div>
    </Popover.Button>
  );
};

const NotificationsPanel: FC = () => {
  const navigate = useNavigate();
  const { authedProviderUser, refreshAuthedProviderUserNotifications } =
    useAuth();

  const [markProviderUserNotificationRead] =
    useMarkProviderUserNotificationReadMutation();
  const [markAllProviderUserNotificationsRead] =
    useMarkAllProviderUserNotificationsReadMutation();

  const notifications = authedProviderUser?.notifications ?? [];

  const hasUnreadNotifications = notifications.some(
    (notification) => !notification.readAt,
  );

  const unreadNotificationCount = notifications.reduce(
    (count, notification) => (notification.readAt ? count : count + 1),
    0,
  );

  const handleMarkAllReadClick = async (): Promise<void> => {
    await markAllProviderUserNotificationsRead();
    await refreshAuthedProviderUserNotifications();
    trackProviderEvent(ProviderAnalyticsEvent.NotificationsAllMarkedAsRead, {
      unreadNotificationCount,
    });
  };

  const handleNotifictionPanelClicked = (open: boolean): void => {
    // Note: open state here on click is what it was before the click, not after.
    if (!open) {
      trackProviderEvent(ProviderAnalyticsEvent.NotificationsPanelOpened, {
        hasUnreadNotifications,
        unreadNotificationCount,
      });
    }
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button as="div">
            <IconButton
              aria-label="Notification menu"
              className={classNames(
                'mr-2 hover:bg-neutral-75',
                open && 'bg-neutral-75',
              )}
              iconClassName="text-secondary-100"
              IconComponent={
                hasUnreadNotifications
                  ? NotificationBellBadge
                  : NotificationBell
              }
              onClick={() => handleNotifictionPanelClicked(open)}
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="absolute -right-14 top-[54px] max-h-[80vh] w-[455px] overflow-y-scroll rounded-lg bg-white shadow-400 ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="sticky top-0 flex items-center justify-between border-b border-neutral-75 bg-white py-3 px-5">
                <div className="text-small-caption font-bold text-green-150">
                  Notifications
                </div>
                <Button
                  title="Mark all as read"
                  theme="none"
                  size="extra-small"
                  noBackground
                  className={classNames(
                    'text-small-caption font-bold',
                    hasUnreadNotifications
                      ? 'text-neutral-125'
                      : 'text-neutral-100',
                  )}
                  onClick={handleMarkAllReadClick}
                  disabled={!hasUnreadNotifications}
                />
              </div>
              {notifications.length === 0 ? (
                <div className="flex flex-col items-center justify-center px-16 pb-8 pt-5 text-center">
                  <div className="mb-1 text-caption font-medium text-neutral-125">
                    No notifications yet
                  </div>
                  <div className="text-caption font-light text-neutral-110">
                    When a client activates, comments, or completes an activity,
                    you’ll be notified here.
                  </div>
                </div>
              ) : (
                <div className="divide-y divide-neutral-50">
                  {notifications.map((notification) => (
                    <NotificationItem
                      key={`notification-${notification.id}`}
                      notification={notification as NotificationDataFragment}
                      onClick={(notification) =>
                        handleNotificationClick({
                          navigate,
                          notification,
                          markProviderUserNotificationRead,
                          refreshAuthedProviderUserNotifications,
                        })
                      }
                    />
                  ))}
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default NotificationsPanel;
