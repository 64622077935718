import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useFeatureFlags } from '../../contexts/FeatureFlagContext';
import { Feature } from '../../generated/graphql';
import { isProviderUserOwnerOrAdminAuthorized } from '../../lib/auth';
import { useAuth } from '../../contexts/AuthContext';

export default function TopNav() {
  const { pathname } = useLocation();
  const { isFeatureEnabled } = useFeatureFlags();
  const { authedProviderUser } = useAuth();

  const hasStripeIntegrationEnabled = isFeatureEnabled(
    Feature.StripeIntegration,
  );

  const hasCommunityEnabled = isFeatureEnabled(Feature.Community);
  const isCalendarSyncEnabled = isFeatureEnabled(Feature.CalendarSync);
  const hasTeamPerformanceEnabled = isFeatureEnabled(Feature.TeamPerformance);

  const navigation = [
    {
      name: 'Home',
      path: '/home',
    },
    {
      name: 'Clients',
      path: '/clients',
    },
    {
      name: 'Library',
      path: '/library',
    },
    ...(isProviderUserOwnerOrAdminAuthorized(authedProviderUser)
      ? [{ name: 'Services', path: '/services' }]
      : []),
    ...(isCalendarSyncEnabled
      ? [
          {
            name: 'Calendar',
            path: '/calendar',
          },
        ]
      : []),
    ...(hasStripeIntegrationEnabled
      ? [
          {
            name: 'Payments',
            path: '/payments',
          },
        ]
      : []),
    ...(hasCommunityEnabled ? [{ name: 'Community', path: '/community' }] : []),
    ...(hasTeamPerformanceEnabled &&
    isProviderUserOwnerOrAdminAuthorized(authedProviderUser)
      ? [{ name: 'Team Performance', path: '/team-performance' }]
      : []),
  ];

  return (
    <nav
      className="flex flex-row items-center justify-start"
      aria-label="Navigation items"
    >
      {navigation.map((navItem) => {
        const { name, path } = navItem;
        const isActiveTab = pathname.startsWith(path);
        const isCommunityActive = pathname.startsWith('/community');
        return (
          <Link
            key={name}
            to={path}
            className={classNames(
              'flex items-center justify-center rounded-md px-3 text-caption transition-colors focus:outline-secondary-100',
              isActiveTab
                ? 'text-green-150'
                : 'text-neutral-110 hover:text-green-150',
              isCommunityActive &&
                !isActiveTab &&
                'opacity-50 hover:opacity-100',
            )}
          >
            {name}
          </Link>
        );
      })}
    </nav>
  );
}
