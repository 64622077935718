// components/LogoUploadField.tsx
import { FC, useState } from 'react';
import classNames from 'classnames';
import { XIcon } from '@heroicons/react/outline';

import { acceptedImageFileExtensions } from '../../../../lib/media';
import Spinner from '../../../../svgs/Spinner';
import IconButton from '../../../../components/IconButton';
import UploadField from '../../../../components/UploadField';

import { LogoUploadProps } from '../types';

export const LogoUploadField: FC<LogoUploadProps> = ({
  logoType,
  logoPreview,
  onUpload,
  onDelete,
  altText,
  isUploading,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files?.length) {
      onUpload(files, logoType);
    }
  };

  const getDisplayFilename = (url: string): string => {
    const filename = url.split('/').pop()?.split('?')[0] || '';
    const extension = filename.match(/\.[^.]+$/)?.[0] || '';
    const nameWithoutExtension = filename.replace(extension, '');
    // Split by last underscore and take the first part
    const displayName = nameWithoutExtension.split(/_(?=[^_]+$)/)[0];
    return displayName + extension;
  };

  return (
    <div
      className={classNames(
        'flex h-[68px] w-full items-center justify-center rounded-md border-[1.5px] bg-neutral-50 text-caption text-neutral-125 transition-all transition-colors',
        !isDragging && 'border-dashed border-secondary-75',
        isDragging && 'border-secondary-100',
      )}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {!logoPreview ? (
        <UploadField
          acceptedFileExtensions={acceptedImageFileExtensions.join(', ')}
          inputId={logoType}
          title="Upload a JPG or PNG image file"
          onChange={(e) => onUpload(e.target.files, logoType)}
        />
      ) : (
        <div className="flex w-full flex-row items-start justify-between p-4">
          <div className="flex w-11/12 flex-row items-center justify-start">
            <div className="relative mr-4 h-[42px] w-[42px] flex-none">
              <img
                src={logoPreview}
                className={classNames(
                  'h-full w-full rounded-md object-contain',
                  isUploading && 'opacity-50',
                )}
                alt={altText}
              />
              {isUploading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <Spinner className="h-4 w-4" />
                </div>
              )}
            </div>
            <div className="flex flex-col items-start justify-start">
              <div className="w-full text-caption font-medium text-neutral-150 line-clamp-1">
                {isUploading ? 'Uploading...' : getDisplayFilename(logoPreview)}
              </div>
              <div className="text-small-caption text-neutral-125">Image</div>
            </div>
          </div>
          <IconButton
            IconComponent={XIcon}
            className="h-5 w-5 text-primary-150"
            onClick={() => onDelete(logoType)}
            aria-label={`Delete ${altText}`}
          />
        </div>
      )}
    </div>
  );
};

export default LogoUploadField;
