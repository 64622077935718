import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IntercomProvider } from 'react-use-intercom';
import { Auth0Provider } from '@auth0/auth0-react';

import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { apolloClient } from './lib/apolloHelpers';
import Config from './lib/config';
import { PatientAuthProvider } from './contexts/PatientAuthContext';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import SentryHelpers from './lib/sentry';
SentryHelpers.initSentry();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <Auth0Provider
        domain={Config.REACT_APP_AUTH0_DOMAIN}
        clientId={Config.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          audience: Config.REACT_APP_AUTH0_API_AUDIENCE,
          redirect_uri: Config.REACT_APP_AUTH0_REDIRECT_URI,
        }}
      >
        <IntercomProvider appId={Config.REACT_APP_INTERCOM_APP_ID} autoBoot>
          <BrowserRouter>
            <AuthProvider>
              <PatientAuthProvider>
                {/* Drag and drop provider. */}
                <DndProvider backend={HTML5Backend}>
                  <HelmetProvider>
                    <App />
                  </HelmetProvider>
                </DndProvider>
              </PatientAuthProvider>
            </AuthProvider>
          </BrowserRouter>
        </IntercomProvider>
      </Auth0Provider>
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
