import classNames from 'classnames';
import { FC, useState } from 'react';
import { useDrop } from 'react-dnd';

import { CollectionIcon } from '@heroicons/react/outline';
import { getTimeStamp } from '../../../../lib/time';
import { ProviderCollection } from '../../../../types/providerCollection';
import Button from '../../../Button';
import { ActivityDataFragment } from '../../../../../generated/graphql';
import { DragTypes } from '../../../../lib/constants';

const ProviderCollectionTableRow: FC<{
  providerCollection: ProviderCollection;
  setSelectedProviderCollection: React.Dispatch<
    React.SetStateAction<ProviderCollection | undefined>
  >;
  followUpMode: boolean;
  sendProviderActivitiesInFollowUp?: (
    providerActivities: ActivityDataFragment[],
  ) => void;
  providerActivitiesInCollection: ActivityDataFragment[] | undefined;
  addActivityToProviderCollection: (
    providerCollection: ProviderCollection,
    providerActivity: ActivityDataFragment,
  ) => Promise<void>;
}> = ({
  providerCollection,
  setSelectedProviderCollection,
  followUpMode,
  sendProviderActivitiesInFollowUp,
  providerActivitiesInCollection,
  addActivityToProviderCollection,
}) => {
  const onClick = () => {
    setSelectedProviderCollection(providerCollection);
  };

  const [showActionButton, setShowActionButton] = useState(false);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: DragTypes.LIBRARY_ITEM,
    drop: (item: { providerActivity: ActivityDataFragment; id: string }) => {
      return addActivityToProviderCollection(
        providerCollection,
        item.providerActivity,
      );
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <tr
      ref={drop}
      className={classNames(
        'max-h-[48px] cursor-pointer text-ellipsis border-b border-neutral-50 text-caption text-secondary-150 hover:bg-neutral-25',
        isOver && canDrop && 'bg-secondary-20',
      )}
      onClick={onClick}
      onMouseEnter={() => setShowActionButton(true)}
      onMouseLeave={() => {
        setShowActionButton(false);
      }}
    >
      <td></td>
      <td className={classNames(!followUpMode ? 'w-2/3' : 'w-1/2')}>
        <div className="flex items-center">
          <div className="mr-4 flex h-[36px] w-[36px] flex-row items-center justify-center rounded-lg">
            <CollectionIcon className="h-[30px] w-[30px] text-secondary-150" />
          </div>
          <div
            className={classNames(
              'font-medium text-neutral-150',
              !followUpMode ? 'line-clamp-2' : 'line-clamp-1',
            )}
          >
            {providerCollection.name}
          </div>
        </div>
      </td>

      <td className="w-1/3">
        <div className="text-caption">
          {getTimeStamp(providerCollection.createdAt, false)}
        </div>
      </td>
      <td>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            sendProviderActivitiesInFollowUp?.(
              providerActivitiesInCollection ?? [],
            );
          }}
          className={classNames(
            !followUpMode || showActionButton ? 'visible' : 'invisible',
          )}
          size="small"
          theme="secondary"
          title={followUpMode ? 'Attach this collection' : 'Send to client'}
        />
      </td>
      <td></td>
    </tr>
  );
};

export default ProviderCollectionTableRow;
