import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { Direction, Impact } from '../../../generated/graphql';

const PerformanceOverview = ({ data, loading }) => {
  return (
    <div className="mb-8 flex w-full flex-row justify-center">
      <div className="grid w-full max-w-4xl grid-cols-2 gap-4 md:grid-cols-4">
        {data.map((item, index) => (
          <div
            key={index}
            className={classNames(
              'flex flex-col items-center justify-center gap-y-2 p-4',
              'transition-opacity duration-300',
              { 'opacity-50': loading },
            )}
          >
            <div className="font-sans text-title-small font-medium text-green-150 transition-all duration-300">
              {item.value}
            </div>
            <div
              className={classNames(
                'flex items-center',
                'font-sans text-caption transition-all duration-300',
                {
                  'text-green-100': item.impact === Impact.Positive,
                  'text-red-100': item.impact === Impact.Negative,
                  'text-neutral-100': item.impact === Impact.Neutral,
                  'opacity-50': loading,
                },
              )}
            >
              <span className="flex items-center transition-transform duration-300">
                {item.direction === Direction.Up ? (
                  <ChevronUpIcon className="h-5 w-5" />
                ) : item.direction === Direction.Down ? (
                  <ChevronDownIcon className="h-5 w-5" />
                ) : null}
                {item.changeInPercentage}%
              </span>
            </div>
            <div className="big-label font-sans font-medium text-green-150">
              {item.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PerformanceOverview;
