import { FC } from 'react';
import classNames from 'classnames';

import { ProviderCollection } from '../../../generated/graphql';
import { CollectionIcon } from '@heroicons/react/outline';

const ProviderCollectionsList: FC<{
  providerCollections: ProviderCollection[] | undefined;
  selectedProviderCollection: ProviderCollection | undefined;
  setSelectedProviderCollection: (
    value: React.SetStateAction<ProviderCollection | undefined>,
  ) => void;
  clearSearchAndFilters: () => void;
}> = ({
  providerCollections,
  selectedProviderCollection,
  setSelectedProviderCollection,
  clearSearchAndFilters,
}) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-start gap-y-2 overflow-y-auto overscroll-y-contain">
      {providerCollections?.map((providerCollection, index) => {
        const isSelected =
          selectedProviderCollection?.id === providerCollection.id;

        return (
          <div
            key={index}
            className={classNames('flex w-full pr-5', {
              'mb-6': index === providerCollections.length - 1,
            })}
          >
            <div
              className={classNames(
                'flex min-h-[40px] w-full cursor-pointer items-center overflow-y-auto rounded-md pr-[14px] pl-4 hover:rounded-md hover:bg-neutral-25',
                {
                  'rounded-md bg-secondary-20 font-medium hover:bg-secondary-25':
                    isSelected,
                },
              )}
              onClick={() => {
                clearSearchAndFilters();
                setSelectedProviderCollection(providerCollection);
              }}
            >
              <CollectionIcon className="ml-1 mr-1 h-[16px] w-[16px] text-secondary-125" />
              <div className="w-[calc(100%-20px)] truncate pl-1 text-caption font-medium text-neutral-150">
                {providerCollection.name}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProviderCollectionsList;
