import { FC } from 'react';

import {
  PatientLifecycleStatusV2,
  PatientsCount,
} from '../../../../generated/graphql';
import Button from '../../../components/Button';
import butterflyImage from '../../../../assets/images/hc-butterfly.png';

import ClearFiltersButton from '../../../components/ClearFiltersButton';
import { PlusSmIcon } from '@heroicons/react/outline';

const EmptyPatientTable: FC<{
  tableType: PatientLifecycleStatusV2;
  patientsCount: PatientsCount;
  addPatients?: () => void;
  clearFilters: () => void;
}> = ({ tableType, patientsCount, addPatients, clearFilters }) => {
  const showAddClientsButton =
    tableType === PatientLifecycleStatusV2.Active &&
    patientsCount?.activePatientsCount === 0;

  const showClearFiltersButton =
    (tableType === PatientLifecycleStatusV2.Invited &&
      patientsCount?.invitedPatientsCount > 0) ||
    (tableType === PatientLifecycleStatusV2.Active &&
      patientsCount?.activePatientsCount > 0) ||
    (tableType === PatientLifecycleStatusV2.Archived &&
      patientsCount?.archivedPatientsCount > 0);

  return (
    <div className="flex h-full w-full flex-col items-center justify-center p-1">
      <img src={butterflyImage} className="m-3 mt-6 h-[64px] w-[79px]" alt="" />
      <h3 className="mb-2 font-serif text-subtitle-small text-green-150">
        Nothing to see here...
      </h3>
      <div className="text-caption text-neutral-125">
        {tableType === PatientLifecycleStatusV2.Invited && (
          <>
            {patientsCount?.invitedPatientsCount === 0 &&
              'Your invited clients will appear here'}
            {patientsCount?.invitedPatientsCount > 0 &&
              'No clients match this filter!'}
          </>
        )}
        {tableType === PatientLifecycleStatusV2.Active && (
          <>
            {patientsCount?.activePatientsCount === 0 &&
              'Your active clients will appear here'}
            {patientsCount?.activePatientsCount > 0 &&
              'No clients match this filter!'}
          </>
        )}
        {tableType === PatientLifecycleStatusV2.Archived && (
          <>
            {patientsCount?.archivedPatientsCount === 0 &&
              'Your archived clients will appear here'}
            {patientsCount?.archivedPatientsCount > 0 &&
              'No clients match this filter!'}
          </>
        )}
      </div>
      <div className="mt-6">
        {showAddClientsButton ? (
          <Button
            theme="secondary"
            size="small"
            title={'Add clients'}
            onClick={() => addPatients?.()}
            IconComponent={PlusSmIcon}
          />
        ) : showClearFiltersButton ? (
          <ClearFiltersButton
            hasFilters={showClearFiltersButton}
            clearFilters={clearFilters}
          />
        ) : null}
      </div>
    </div>
  );
};

export default EmptyPatientTable;
