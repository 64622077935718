import classNames from 'classnames';
import { Link } from 'react-router-dom';
import hcLogo from '../../assets/images/logo/logo-green-xl.png';
import {
  trackPatientEvent,
  trackProviderEvent,
  CommonAnalyticsEvent,
  AnalyticsComponent,
} from '../../lib/analytics';
import { Transition } from '@headlessui/react';

type LogoProps = {
  customLogoUrl?: string | null;
  hasLoadedUser: boolean;
  linkTo?: string;
  isPatient?: boolean;
  showDefaultLogo?: boolean;
};

export default function HeaderLogo({
  customLogoUrl,
  hasLoadedUser,
  linkTo = '/',
  isPatient = false,
  showDefaultLogo = true,
}: LogoProps) {
  const handleClick = () => {
    const trackEvent = isPatient ? trackPatientEvent : trackProviderEvent;
    trackEvent(CommonAnalyticsEvent.ButtonClicked, {
      buttonName: 'Logo',
      originComponent: AnalyticsComponent.MainHeader,
    });
  };

  return (
    <Link
      to={linkTo}
      className="mr-2 flex h-full items-center justify-center md:mr-5"
      onClick={handleClick}
    >
      <div className="relative h-9 w-9">
        <Transition show={hasLoadedUser}>
          <>
            <img
              src={hcLogo}
              className={classNames(
                'absolute h-full w-full transition-opacity duration-300',
                customLogoUrl ? 'opacity-0' : 'opacity-100',
                !showDefaultLogo && 'invisible',
              )}
              alt="Homecoming logo"
            />
            {customLogoUrl && (
              <img
                src={customLogoUrl}
                className="absolute h-full w-full object-contain opacity-100 transition-opacity duration-300"
                alt="Custom logo"
              />
            )}
          </>
        </Transition>
      </div>
    </Link>
  );
}
