import tinycolor from 'tinycolor2';
import { ProgramTagColor } from '../../generated/graphql';

// TODO: resolveConfig from OG Tailwind colors in ./
export const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  white: '#ffffff',
  black: '#000000',
  green: {
    200: '#001E07',
    150: '#002D0A',
    125: '#004704',
    100: '#00B20A',
    75: '#44C74C',
    50: '#8ADD8E',
    25: '#E2FBE5',
    20: '#F0F9F1',
  },
  blue: {
    150: '#001275',
    125: '#0021DB',
    100: '#2D4BFF',
    50: '#9EADFF',
    25: '#D8DEFF',
  },
  purple: {
    150: '#461452',
    125: '#79198F',
    100: '#B000D9',
    50: '#E98FFF',
    25: '#F6D1FF',
  },
  red: {
    150: '#4D0B00',
    125: '#901500',
    100: '#E23213',
    50: '#F1785F',
    25: '#FAC5BD',
  },
  orange: {
    150: '#553300',
    125: '#804D00',
    100: '#FFA41C',
    50: '#FCCC80',
    25: '#FFDCA6',
  },
  silver: {
    100: '#F2F5F6',
  },
  neutral: {
    200: '#000F0A',
    150: '#232B29',
    125: '#394642',
    110: '#68716E',
    100: '#DBD8D1',
    75: '#EAE8E4',
    50: '#F4F3F1',
    30: '#F8F8F7',
    25: '#FAF9F8',
    20: '#FDFDFC',
    0: '#FFFFFF',
  },
};

export const DEFAULT_COLORS = {
  PRIMARY: colors.neutral[125],
  SECONDARY: colors.green[100],
} as const;

type AssessmentsColorType = {
  hover: string;
  outer: string;
  middle: string;
  default: string;
  defaultHex?: string;
};

export const ASSESSMENTS_COLOR_MAP: Record<string, AssessmentsColorType> = {
  neutral: {
    hover: 'hover:bg-neutral-100',
    outer: 'bg-neutral-100/20',
    middle: 'bg-neutral-100/50',
    default: 'bg-neutral-100',
    defaultHex: colors.neutral[100],
  },
  'red-25': {
    hover: 'hover:bg-red-25',
    outer: 'bg-red-25/20',
    middle: 'bg-red-25/50',
    default: 'bg-red-25',
    defaultHex: colors.red[25],
  },
  'red-50': {
    hover: 'hover:bg-red-50',
    outer: 'bg-red-50/20',
    middle: 'bg-red-50/50',
    default: 'bg-red-50',
    defaultHex: colors.red[50],
  },
  'orange-50': {
    hover: 'hover:bg-orange-50',
    outer: 'bg-orange-50/20',
    middle: 'bg-orange-50/50',
    default: 'bg-orange-50',
    defaultHex: colors.orange[50],
  },
  'green-25': {
    hover: 'hover:bg-green-25',
    outer: 'bg-green-25/20',
    middle: 'bg-green-25/50',
    default: 'bg-green-25',
    defaultHex: colors.green[25],
  },
  'green-50': {
    hover: 'hover:bg-green-50',
    outer: 'bg-green-50/20',
    middle: 'bg-green-50/50',
    default: 'bg-green-50',
    defaultHex: colors.green[50],
  },
  'blue-25': {
    hover: 'hover:bg-blue-25',
    outer: 'bg-blue-25/20',
    middle: 'bg-blue-25/50',
    default: 'bg-blue-25',
    defaultHex: colors.blue[25],
  },
  'purple-50': {
    hover: 'hover:bg-purple-50',
    outer: 'bg-purple-50/20',
    middle: 'bg-purple-50/50',
    default: 'bg-purple-50',
    defaultHex: colors.purple[50],
  },
};

export const PROGRAM_TAG_COLOR_TO_TAILWIND_BG_COLOR_MAP: Record<
  ProgramTagColor,
  string
> = {
  [ProgramTagColor.Gray]: 'bg-neutral-50',
  [ProgramTagColor.Red]: 'bg-red-25',
  [ProgramTagColor.Orange]: 'bg-orange-25',
  [ProgramTagColor.Green]: 'bg-green-25',
  [ProgramTagColor.Blue]: 'bg-blue-25',
  [ProgramTagColor.Purple]: 'bg-purple-25',
};

export const PROGRAM_TAG_COLOR_TO_TAILWIND_HOVER_COLOR_MAP: Record<
  ProgramTagColor,
  string
> = {
  [ProgramTagColor.Gray]: 'hover:bg-neutral-50/75',
  [ProgramTagColor.Red]: 'hover:bg-red-25/75',
  [ProgramTagColor.Orange]: 'hover:bg-orange-25/75',
  [ProgramTagColor.Green]: 'hover:bg-green-25/75',
  [ProgramTagColor.Blue]: 'hover:bg-blue-25/75',
  [ProgramTagColor.Purple]: 'hover:bg-purple-25/75',
};

export const DEFAULT_CALENDAR_HEX_COLOR = colors.blue[50];

export const CALENDAR_HEX_COLOR_MAP: Record<string, string> = {
  [ProgramTagColor.Green]: colors.green[50],
  [ProgramTagColor.Blue]: colors.blue[50],
  [ProgramTagColor.Purple]: colors.purple[50],
  [ProgramTagColor.Red]: colors.red[50],
  [ProgramTagColor.Orange]: colors.orange[50],
};

export const COLOR_VARIANTS = {
  primary: {
    root: { name: '--primary-color-125', adjustment: 0 },
    variants: [
      { name: '--primary-color-200', adjustment: -18.75 }, // darker
      { name: '--primary-color-150', adjustment: -6.25 },
      { name: '--primary-color-110', adjustment: 3.75 }, // lighter
      { name: '--primary-color-100', adjustment: 6.25 },
      { name: '--primary-color-75', adjustment: 12.5 },
      { name: '--primary-color-50', adjustment: 18.75 },
      { name: '--primary-color-30', adjustment: 23.75 },
      { name: '--primary-color-25', adjustment: 25.5 },
      { name: '--primary-color-20', adjustment: 26.75 },
      { name: '--primary-color-0', adjustment: 'white' },
    ],
  },
  secondary: {
    root: { name: '--secondary-color-100', adjustment: 0 },
    variants: [
      { name: '--secondary-color-150', adjustment: -12.5 }, // darker
      { name: '--secondary-color-125', adjustment: -6.25 },
      { name: '--secondary-color-75', adjustment: 6.25 }, // lighter
      { name: '--secondary-color-50', adjustment: 12.5 }, // lighter
      { name: '--secondary-color-25', adjustment: 19.5 },
      { name: '--secondary-color-20', adjustment: 21 },
    ],
  },
} as const;

type ColorVariant = {
  name: string;
  adjustment: number | 'white';
};

export const applyColorToRoot = (
  color: string,
  colorType: 'primary' | 'secondary',
) => {
  const root = document.documentElement;
  const config = COLOR_VARIANTS[colorType];
  const isDefaultPrimary =
    colorType === 'primary' && (color === DEFAULT_COLORS.PRIMARY || !color);
  const isDefaultSecondary =
    colorType === 'secondary' && (color === DEFAULT_COLORS.SECONDARY || !color);

  // Set root color
  root.style.setProperty(config.root.name, color);

  // Handle defaults
  if (isDefaultPrimary) {
    // Set neutral color variants directly
    root.style.setProperty('--primary-color-200', colors.neutral[200]);
    root.style.setProperty('--primary-color-150', colors.neutral[150]);
    root.style.setProperty('--primary-color-125', colors.neutral[125]);
    root.style.setProperty('--primary-color-110', colors.neutral[110]);
    root.style.setProperty('--primary-color-100', colors.neutral[100]);
    root.style.setProperty('--primary-color-75', colors.neutral[75]);
    root.style.setProperty('--primary-color-50', colors.neutral[50]);
    root.style.setProperty('--primary-color-30', colors.neutral[30]);
    root.style.setProperty('--primary-color-25', colors.neutral[25]);
    root.style.setProperty('--primary-color-20', colors.neutral[20]);
    root.style.setProperty('--primary-color-0', colors.white);
  } else if (isDefaultSecondary) {
    // Set green color variants directly
    root.style.setProperty('--secondary-color-200', colors.green[200]);
    root.style.setProperty('--secondary-color-150', colors.green[150]);
    root.style.setProperty('--secondary-color-125', colors.green[125]);
    root.style.setProperty('--secondary-color-100', colors.green[100]);
    root.style.setProperty('--secondary-color-75', colors.green[75]);
    root.style.setProperty('--secondary-color-50', colors.green[50]);
    root.style.setProperty('--secondary-color-25', colors.green[25]);
    root.style.setProperty('--secondary-color-20', colors.green[20]);
  } else {
    // Use tinycolor adjustments for custom colors
    const tc = tinycolor(color);
    config.variants.forEach(({ name, adjustment }: ColorVariant) => {
      const value =
        adjustment === 'white'
          ? colors.white
          : adjustment > 0
          ? tc.lighten(adjustment).toHexString()
          : tc.darken(Math.abs(adjustment)).toHexString();
      root.style.setProperty(name, value);
    });
  }
};

export const shouldUseProviderPrimaryColor = (
  color: string | null,
): boolean => {
  return color !== colors.neutral[125] && color !== null;
};

export const shouldUseProviderSecondaryColor = (
  color: string | null,
): boolean => {
  return color !== colors.green[100] && color !== null;
};
