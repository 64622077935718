import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { ApolloClient, from, InMemoryCache } from '@apollo/client';

import Config from './config';
import {
  clearAuth0AccessToken,
  clearTokenPayload,
  getAuth0AccessToken,
  getPatientTokenPayload,
  getTokenPayload,
} from './auth';
import { CustomGraphQLError, HomecomingError } from './errors';
import { isClientUrl } from '../v2/lib/url';
import Auth0Helpers from './auth0';
import { customToast } from '../v2/components/ToastAlert/customToast';

const uploadHttpLink = createUploadLink({
  uri: `${Config.REACT_APP_HOMECOMING_API_BASE_URI}/graphql`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // Don't trigger logout on network errors
  if (networkError) {
    customToast.error(
      'We are experiencing issues with our network. Please try refreshing the page.',
    );
    return;
  }

  if (
    (graphQLErrors?.[0] as CustomGraphQLError)?.code ===
    HomecomingError.TokenInvalid
  ) {
    // Clear the token payload in cookie storage and redirect to Login
    clearTokenPayload();
    clearAuth0AccessToken();
    // Need to log out of Auth0 as well
    Auth0Helpers.logout();
    window.location.href = '/login';
  }
});

export const TOKEN_TYPE_HEADER = 'x-token-type';
export const AUTH0_TOKEN_TYPE = 'auth0';

const authLink = setContext((_, { headers }) => {
  const auth0AccessToken = getAuth0AccessToken();
  const providerTokenPayload = getTokenPayload();
  const patientTokenPayload = getPatientTokenPayload();

  // Uses specific client URLs to use patient authentication
  const isPatientRequest = isClientUrl();

  const isAuth0Request = !isPatientRequest && auth0AccessToken;

  const authToken = isPatientRequest
    ? patientTokenPayload?.authSessionToken
    : auth0AccessToken || providerTokenPayload?.authToken;

  return {
    headers: {
      ...headers,
      ...(authToken && {
        authorization: `Bearer ${authToken}`,
      }),
      // This header is used to differentiate between Auth0 and other token types.
      // Critically, this differentiates between the server running our existing authentication
      // scheme for clients/patients, as well as both providers & clients/patients on the mobile app.
      ...(isAuth0Request && {
        [TOKEN_TYPE_HEADER]: AUTH0_TOKEN_TYPE,
      }),
    },
  };
});

const retryLink = new RetryLink({
  attempts: {
    // GQL errors don't trigger retries, only network errors (according to the docs).
    retryIf: (error) => {
      if (typeof error !== 'object') {
        return false;
      }

      // Internet is offline or server is down.
      return error?.message === 'Failed to fetch';
    },
  },
});

export const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, retryLink, uploadHttpLink]),
  cache: new InMemoryCache({
    possibleTypes: {
      Activity: [
        'ActivityAssessment',
        'ActivityAction',
        'ActivityImage',
        'ActivityLink',
        'ActivityPDF',
        'ActivityText',
      ],
    },
    // Cache policies defined by type, e.g. how to merge incoming data with existing data.
    typePolicies: {
      ProgramTemplateTaskGroup: {
        fields: {
          tasks: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Task: {
        fields: {
          media: {
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },
});
